import styled from '@emotion/styled';
import {colors, responsive} from 'ui';

export const Outer = styled.div`
  display: flex;
  flex: 1 auto;
  width: 100%;
  min-height: 100%;
  background: ${colors.darkBlue};
`;

export const Btn = styled.span`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 8px;
  img {
    width: 30px;
  }
`;

export const Navigation = styled.nav`
  position: fixed;
  height: 100vh;
  left: 0;
  background: ${colors.darkBlue};
  width: 70px;
  z-index: 3;

  ${responsive.smAndLess} {
    width: ${(props) => (props.show === true ? `5%` : `0%`)};
    z-index: ${(props) => props.show === true && `3`};
  }
`;

export const NavBtnWrapper = styled.div`
  border-left: ${(props) =>
    props.selected ? `3px ${colors.orange} solid` : `3px transparent solid`};

  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }

  p {
    color: ${(props) =>
      props.selected ? colors.orange : props.access ? 'white' : 'gray'};
    font-size: 10px;
    text-align: center;
    padding-bottom: 5px;
  }

  * {
    cursor: ${(props) => (props.access ? 'cursor' : 'not-allowed')};
  }
`;

export const Main = styled.main`
  position: relative;
  left: 70px;
  width: calc(100% - 70px);
  height: 100vh;
  z-index: 1;
  ${responsive.smAndLess} {
    left: 0%;
    width: 100%;
  }
`;

export const NavOuter = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  right: 0;
`;

export const FlexItems = styled.div`
  width: 100%;
  height: 80%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 22px;
    background: #3a4750;
  }

  &::-webkit-scrollbar-thumb {
    background: #919ba1;
    border-radius: 22px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #777;
  }
`;

export const NavInner = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`;

export const FixedNav = styled.div`
  position: fixed;
  height: 100%;
  transform: translateX(0%);

  @keyframes slideIn {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(5%);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateX(5%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  ${responsive.smAndLess} {
    padding: ${(props) => props.show === true && `0px 15px 0px 10px`};
    background-color: ${colors.darkBlue};
    animation: ${(props) =>
      props.show === true ? `slideIn 0.4s forwards` : `slideOut 0.4s forwards`};
  }
`;

export const Logo = styled.img``;

export const LogoWrapper = styled.div`
  height: 20%;
  justify-content: center;
  /* margin: 40px 0px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 5px transparent solid;
  p {
    margin-top: 8px;
    color: white;
    font-size: 8px;
  }
`;

export const BackBtnWrapper = styled.div`
  height: 20%;
  justify-content: center;
  border-left: 10px transparent solid;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
`;

export const BackBtn = styled.a`
  z-index: 2;
  border-radius: 50%;
  background: #fff;
  height: 45px;
  width: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0, 0);
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #efefef;
  }
  img {
    width: 50%;
  }
  transform: ${(props) => props.showBackButton === 'true' && `scale(1, 1)`};
`;

export const FooterInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding-top: 5px;
`;

export const FooterItem = styled.div`
  padding: 10px 15px;
  color: white;
`;

export const FixedMobileFooter = styled.div`
  ${responsive.smAndLess} {
    position: fixed;
    height: 50px;
    background-color: ${colors.darkBlue};
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    z-index: 2;
    display: block;
  }
  display: none;
`;

export const ProfileText = styled.p`
  padding-bottom: 10px !important;
`;
