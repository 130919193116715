import {annotations, idForSave} from './annotationsTracker';

export const formatName = (name) => {
  let result = [];
  const nameArray = name.split('-');
  for (let word of nameArray) {
    result.push(word.charAt(0).toUpperCase() + word.slice(1));
  }
  return result.join(' - ');
};

export const profile = {
  userProfile: {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
  },
};

export const colorForCircle = {
  0: {color: 'black', bgcolor: '#F6C90E'},
  1: {color: 'white', bgcolor: '#0B0BFF'},
  2: {color: 'black', bgcolor: '#08FF08'},
  3: {color: 'white', bgcolor: '#FF0A0A'},
};

export function registerSaveApiHandlerUtil(email, route_id, today) {
  // export function registerSaveApiHandlerUtil(email, route_id) {
  const hostName = `${process.env.REACT_APP_GRPS_API}`;
  // const url = `${hostName}/routes/${route_id}?date=${'2021-06-25'}`;
  const url = `${hostName}/routes/${route_id}?date=${today}`;
  /* Define Save API Handler */
  const saveApiHandler = async (metaData, content) => {
    // console.log('saveApiHandler called');
    // console.log('idForSave', idForSave);
    // console.log('metaData.id', metaData.id);
    if (!(metaData.id in idForSave)) {
      console.log('save to server');
      fetch(url, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          updatedFile: Array.from(content),
        }),
        // }).then((response) => {
      }).then(() => {
        // console.log(response);
      });
    }
    delete idForSave[metaData.id];
    return new Promise((resolve) => {
      /* Dummy implementation of Save API, replace with your business logic */
      const response = {
        code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
        data: {
          metaData: Object.assign(metaData, {
            updatedAt: new Date().getTime(),
          }),
        },
      };
      resolve(response);
    });
  };

  this.adobeDCView.registerCallback(
    window.AdobeDC.View.Enum.CallbackType.SAVE_API,
    saveApiHandler,
    {
      autoSaveFrequency: 1,
      showSaveButton: false,
    },
  );

  // const eventOptions = {
  //   //Pass the events to receive.
  //   //If no event is passed in listenOn, then all file preview events will be received.
  //   listenOn: [
  //     window.AdobeDC.View.Enum.Events.APP_RENDERING_START,
  //     window.AdobeDC.View.Enum.FilePreviewEvents.PREVIEW_KEY_DOWN,
  //     window.AdobeDC.View.Enum.FilePreviewEvents.PREVIEW_PAGE_VIEW_SCROLLED,
  //   ],
  //   enableFilePreviewEvents: true,
  // };

  // this.adobeDCView.registerCallback(
  //   window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
  //   function (event) {
  //     console.log(event);
  //     console.log(document.querySelector('iframe'));
  //     console.log(
  //       document.querySelector('iframe').contentWindow
  //       // .contentWindow.document.querySelector('.sdk-Branding-branding')
  //     );
  //   },
  //   eventOptions
  // );

  profile.userProfile.name = email;
  profile.userProfile.email = email;
  this.adobeDCView.registerCallback(
    window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
    function () {
      return new Promise((resolve) => {
        resolve({
          code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
          data: profile,
        });
      });
    },
    {},
  );
}

export function previewFileUtil(divId, viewerConfig, email, socket, pdfUrl) {
  // console.log(process.env.NODE_ENV);
  const config = {
    /* Pass your registered client id */
    clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
  };
  if (divId) {
    /* Optional only for Light Box embed mode */
    /* Pass the div id in which PDF should be rendered */
    config.divId = divId;
  }
  /* Initialize the AdobeDC View object */
  this.adobeDCView = new window.AdobeDC.View(config);

  /* Invoke the file preview API on Adobe DC View object */
  const previewFilePromise = this.adobeDCView.previewFile(
    {
      /* Pass information on how to access the file */
      content: {
        /* Location of file where it is hosted */
        location: {
          url:
            // 'https://visualizer-pdf-testing.s3.eu-north-1.amazonaws.com/Bodea+Brochure+(6).pdf',
            // 'https://visualizer-pdf-testing.s3.eu-north-1.amazonaws.com/some.pdf',
            pdfUrl,
          // 'https://40.83.206.11/',
          // 'http://localhost:8000',
          // 'http://51.120.3.118',
          /*
                    If the file URL requires some additional headers, then it can be passed as follows:-
                    headers: [
                        {
                            key: "<HEADER_KEY>",
                            value: "<HEADER_VALUE>",
                        }
                    ]
                    */
        },
      },
      /* Pass meta data of file */
      metaData: {
        fileName: 'Cemit Visualizer.pdf',
        id: '6d07d124-ac85-43b3-a867-36930f502ac6',
      },
    },
    viewerConfig,
  );

  previewFilePromise.then((adobeViewer) => {
    adobeViewer.getAnnotationManager().then((annotationManager) => {
      annotationManager.registerEventListener(
        function (event) {
          // console.log(
          //   'added annotations event raised, before if check if resend'
          // );
          if (!(event.data.id in annotations)) {
            console.log(
              'register event and about to send if ANNOTATION_ADDED, ANNOTATION_DELETED or ANNOTATION_UPDATED',
            );
            console.log('event.type', event.type);
            if (event.type == 'ANNOTATION_ADDED') {
              // console.log(annotations);
              // if (event.data.creator.id == email) {
              // socket.emit('message', event);
              if (!event.data.target.selector) {
                annotationManager.getAnnotations().then((result) => {
                  result.map((annotation) => {
                    if (event.data.target.source == annotation.id) {
                      event.data.target.created = annotation.created;
                      event.data.target.creator = annotation.creator.name;
                    }
                  });
                  socket.send(JSON.stringify(event));
                });
              } else {
                socket.send(JSON.stringify(event));
              }
            } else if (event.type == 'ANNOTATION_DELETED') {
              socket.send(JSON.stringify(event));
            } else if (event.type == 'ANNOTATION_UPDATED') {
              socket.send(JSON.stringify(event));
            } else if (event.type == 'ANNOTATION_SELECTED') {
              console.log(event);
            }
          }
          // console.log('removes');
          // console.log('event.data.id annotation id', event.data.id);
          // console.log('annotations before delete', annotations);
          delete annotations[event.data.id];
          // console.log('annotations after delete', annotations);
        },
        {
          listenOn: [
            'ANNOTATION_ADDED',
            'ANNOTATION_UPDATED',
            'ANNOTATION_DELETED',
            // 'ANNOTATION_SELECTED',
          ],
        },
      );
    });
  });

  return previewFilePromise;
}

// previewFileUsingFilePromise(divId, filePromise, fileName) {
//   /* Initialize the AdobeDC View object */
//   this.adobeDCView = new window.AdobeDC.View({
//     /* Pass your registered client id */
//     clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
//     /* Pass the div id in which PDF should be rendered */
//     divId,
//   });

//   /* Invoke the file preview API on Adobe DC View object */
//   this.adobeDCView.previewFile(
//     {
//       /* Pass information on how to access the file */
//       content: {
//         /* pass file promise which resolve to arrayBuffer */
//         promise: filePromise,
//       },
//       /* Pass meta data of file */
//       metaData: {
//         /* file name */
//         fileName: fileName,
//       },
//     },
//     {}
//   );
// }
