import {useContext} from 'react';
import {Link, useLocation, navigate} from '@reach/router';

import {LayoutContext} from 'contexts/layout-context';
import {UserContext} from 'contexts/user-context';

import ApolloClient from 'apollo-client';
import {ApolloProvider} from '@apollo/react-hooks';
// Setup the network "links"
import {WebSocketLink} from 'apollo-link-ws';
import {HttpLink} from 'apollo-link-http';
import {split} from 'apollo-link';
import {getMainDefinition} from 'apollo-utilities';
import {InMemoryCache} from 'apollo-cache-inmemory';

import CemitLogo from 'assets/icons/cemit_logo_responsive.svg';

import {colors} from 'ui';

import {
  RailIcon,
  TrainIcon,
  BudgetIcon,
  StationIcon,
  DepotIcon,
  GRPSIcon,
  EventIcon,
  SwitchIcon,
  Profile,
  CatenaryIcon,
} from './sidebar-svg';

import BackArrow from 'assets/icons/back_arrow.svg';
import {
  Outer,
  Navigation,
  LogoWrapper,
  Logo,
  Main,
  Btn,
  NavOuter,
  NavInner,
  FixedNav,
  FlexItems,
  BackBtn,
  BackBtnWrapper,
  FixedMobileFooter,
  FooterInner,
  FooterItem,
  NavBtnWrapper,
  ProfileText,
} from './styles';

const NavBtn = ({children}) => (
  <Btn>
    {children}
    {/* <img src={icon} alt={alt} /> */}
  </Btn>
);

const SUB_GRAPHQL_ENDPOINT = `wss://staging-api.dimensionfour.io/subscription`;
const GRAPHQL_ENDPOINT = `https://staging-api.dimensionfour.io/cemit/graph`;

const httpLink = new HttpLink({
  uri: GRAPHQL_ENDPOINT,
});

const wsLink = new WebSocketLink({
  uri: SUB_GRAPHQL_ENDPOINT,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: {
      'x-tenant-id': 'cemit',
      'x-tenant-key': 'cd62789e5ea1d3ed14cacc63',
    },
  },
});

const link = split(
  ({query}) => {
    const {kind, operation} = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  request: (operation) => {
    operation.setContext({
      headers: {
        'x-tenant-id': 'cemit',
        'x-tenant-key': '3039a50cdda132164199fd43',
      },
    });
  },
  link,
  cache: new InMemoryCache(),
});

let tabAccess = JSON.parse(localStorage.getItem('tabAccess'))?.tabAccess || [];
let group = JSON.parse(localStorage.getItem('group'))?.group || [];

const showOrHide = (value) => (value === true ? 'Hide' : 'Show');

const MobileFooter = ({
  toggleSidebar,
  toggleMapView,
  showSidebarOnSmallScreen,
  showMapOnSmallScreen,
}) => (
  <FixedMobileFooter>
    <FooterInner>
      <FooterItem id="showMapToggleButton" onClick={toggleMapView}>{`${showOrHide(
        showMapOnSmallScreen
      )} map`}</FooterItem>
      <FooterItem id="showSidebarToggleButton" onClick={toggleSidebar}>{`${showOrHide(
        showSidebarOnSmallScreen
      )} sidebar`}</FooterItem>
    </FooterInner>
  </FixedMobileFooter>
);

const ProfileItem = ({currentPath, toggleSidebar}) => (
  <div>
    <Link
      to="/dashboard/profile"
      onClick={() => {
        toggleSidebar();
      }}
    >
      <NavBtnWrapper selected={currentPath.includes('profile')} access={true}>
        <NavBtn>
          <Profile fill={currentPath.includes('profile') ? colors.orange : 'white'} />
        </NavBtn>
        <ProfileText>PROFILE</ProfileText>
      </NavBtnWrapper>
    </Link>
  </div>
);

const NavItems = ({currentPath, toggleSidebar}) => {
  const [userState] = useContext(UserContext);
  let railAccess =
    process.env.REACT_FEATURE_TRACK === 'true' &&
    (tabAccess?.includes('rails') ||
      group == 'sysadmin' ||
      userState.tabAccess?.includes('rails') ||
      userState.group == 'sysadmin');

  let trainAccess =
    process.env.REACT_FEATURE_TRAIN === 'true' &&
    (tabAccess?.includes('train') ||
      group == 'sysadmin' ||
      userState.tabAccess?.includes('train') ||
      userState.group == 'sysadmin');

  let catenaryAccess =
    process.env.REACT_FEATURE_CATENARY === 'true' &&
    (tabAccess?.includes('catenary') ||
      group == 'sysadmin' ||
      userState.tabAccess?.includes('catenary') ||
      userState.group == 'sysadmin');

  let stationsAccess =
    process.env.REACT_FEATURE_STATIONS === 'true' &&
    (tabAccess?.includes('stations') ||
      group == 'sysadmin' ||
      userState.tabAccess?.includes('stations') ||
      userState.group == 'sysadmin');

  let depotsAccess =
    process.env.REACT_FEATURE_DEPOTS === 'true' &&
    (tabAccess?.includes('depots') ||
      group == 'sysadmin' ||
      userState.tabAccess?.includes('depots') ||
      userState.group == 'sysadmin');

  let grpsAccess =
    process.env.REACT_FEATURE_ROUTE_PLANNER === 'true' &&
    (tabAccess?.includes('grps') ||
      group == 'sysadmin' ||
      userState.tabAccess?.includes('grps') ||
      userState.group == 'sysadmin');

  let eventsAccess =
    process.env.REACT_FEATURE_EVENTS === 'true' &&
    (tabAccess?.includes('events') ||
      group === 'sysadmin' ||
      userState.tabAccess?.includes('events') ||
      userState.group === 'sysadmin');

  let switchAccess =
    process.env.REACT_FEATURE_SWITCH === 'true' &&
    (tabAccess?.includes('switch') ||
      group === 'sysadmin' ||
      userState.tabAccess?.includes('switch') ||
      userState.group === 'sysadmin');

  let budgetAccess =
    process.env.REACT_FEATURE_BUDGET === 'true' &&
    (tabAccess?.includes('budget') ||
      group === 'sysadmin' ||
      userState.tabAccess?.includes('budget') ||
      userState.group === 'sysadmin');

  // let depotAcess = false;
  return (
    <div>
      {(process.env.REACT_FEATURE_TRAIN === 'true' ||
        process.env.REACT_FEATURE_MENU_TRAIN === 'true') &&
        (trainAccess ? (
          <Link
            to="/dashboard/train"
            onClick={() => {
              toggleSidebar();
            }}
          >
            <NavBtnWrapper selected={currentPath.includes('train')} access={trainAccess}>
              <NavBtn>
                <TrainIcon
                  fill={
                    currentPath.includes('train')
                      ? colors.orange
                      : trainAccess
                      ? 'white'
                      : 'gray'
                  }
                  stroke={
                    currentPath.includes('train')
                      ? colors.orange
                      : trainAccess
                      ? 'white'
                      : 'gray'
                  }
                />
              </NavBtn>
              <p selected={currentPath.includes('train')}>TRAIN</p>
            </NavBtnWrapper>
          </Link>
        ) : (
          <NavBtnWrapper selected={currentPath.includes('train')} access={trainAccess}>
            <NavBtn>
              <TrainIcon
                fill={
                  currentPath.includes('train')
                    ? colors.orange
                    : trainAccess
                    ? 'white'
                    : 'gray'
                }
              />
            </NavBtn>
            <p selected={currentPath.includes('train')}>TRAIN</p>
          </NavBtnWrapper>
        ))}

      {/* disable link if dont have access */}
      {(process.env.REACT_FEATURE_TRACK === 'true' ||
        process.env.REACT_FEATURE_MENU_TRACK === 'true') &&
        (railAccess ? (
          <Link
            to="/dashboard/index"
            onClick={() => {
              toggleSidebar();
            }}
          >
            <NavBtnWrapper selected={currentPath.includes('index')} access={railAccess}>
              <NavBtn>
                <RailIcon
                  fill={
                    currentPath.includes('index')
                      ? colors.orange
                      : railAccess
                      ? 'white'
                      : 'gray'
                  }
                />
              </NavBtn>
              <p selected={currentPath.includes('index')}>TRACK</p>
            </NavBtnWrapper>
          </Link>
        ) : (
          <NavBtnWrapper selected={currentPath.includes('index')} access={railAccess}>
            <NavBtn>
              <RailIcon
                fill={
                  currentPath.includes('index')
                    ? colors.orange
                    : railAccess
                    ? 'white'
                    : 'gray'
                }
              />
            </NavBtn>
            <p selected={currentPath.includes('index')}>RAILS</p>
          </NavBtnWrapper>
        ))}

      {(process.env.REACT_FEATURE_CATENARY === 'true' ||
        process.env.REACT_FEATURE_MENU_CATENARY === 'true') &&
        (catenaryAccess ? (
          <Link
            to="/dashboard/catenary"
            onClick={() => {
              toggleSidebar();
            }}
          >
            <NavBtnWrapper
              selected={currentPath.includes('catenary')}
              access={catenaryAccess}
            >
              <NavBtn>
                <CatenaryIcon
                  fill={
                    currentPath.includes('catenary')
                      ? colors.orange
                      : catenaryAccess
                      ? 'white'
                      : 'gray'
                  }
                  stroke={
                    currentPath.includes('catenary')
                      ? colors.orange
                      : catenaryAccess
                      ? 'white'
                      : 'gray'
                  }
                />
              </NavBtn>
              <p selected={currentPath.includes('catenary')}>CATENARY</p>
            </NavBtnWrapper>
          </Link>
        ) : (
          <NavBtnWrapper
            selected={currentPath.includes('catenary')}
            access={catenaryAccess}
          >
            <NavBtn>
              <CatenaryIcon
                fill={
                  currentPath.includes('catenary')
                    ? colors.orange
                    : catenaryAccess
                    ? 'white'
                    : 'gray'
                }
              />
            </NavBtn>
            <p selected={currentPath.includes('catenary')}>CATENARY</p>
          </NavBtnWrapper>
        ))}

      {(process.env.REACT_FEATURE_TRAIN_WHEEL_TEMP === 'true' ||
        process.env.REACT_FEATURE_MENU_WHEEL_TEMP === 'true') &&
        (trainAccess ? (
          <Link
            to="/dashboard/train/wheel_temperature"
            onClick={() => {
              toggleSidebar();
            }}
          >
            <NavBtnWrapper selected={currentPath.includes('train')} access={trainAccess}>
              <NavBtn>
                <TrainIcon
                  fill={
                    currentPath.includes('train')
                      ? colors.orange
                      : trainAccess
                      ? 'white'
                      : 'gray'
                  }
                  stroke={
                    currentPath.includes('train')
                      ? colors.orange
                      : trainAccess
                      ? 'white'
                      : 'gray'
                  }
                />
              </NavBtn>
              <p selected={currentPath.includes('train')}>TRAIN</p>
            </NavBtnWrapper>
          </Link>
        ) : (
          <NavBtnWrapper selected={currentPath.includes('train')} access={trainAccess}>
            <NavBtn>
              <TrainIcon
                fill={
                  currentPath.includes('train')
                    ? colors.orange
                    : trainAccess
                    ? 'white'
                    : 'gray'
                }
              />
            </NavBtn>
            <p selected={currentPath.includes('train')}>TRAIN</p>
          </NavBtnWrapper>
        ))}

      {(process.env.REACT_FEATURE_STATIONS === 'true' ||
        process.env.REACT_FEATURE_MENU_STATIONS === 'true') &&
        (stationsAccess ? (
          <Link
            to="/dashboard/stations"
            onClick={() => {
              toggleSidebar();
            }}
          >
            <NavBtnWrapper
              selected={currentPath.includes('stations')}
              access={stationsAccess}
            >
              <NavBtn>
                <StationIcon
                  fill={
                    currentPath.includes('stations')
                      ? colors.orange
                      : stationsAccess
                      ? 'white'
                      : 'gray'
                  }
                  stroke={
                    currentPath.includes('stations')
                      ? colors.orange
                      : stationsAccess
                      ? 'white'
                      : 'gray'
                  }
                />
              </NavBtn>
              <p selected={currentPath.includes('stations')}>STATIONS</p>
            </NavBtnWrapper>
          </Link>
        ) : (
          <NavBtnWrapper
            selected={currentPath.includes('stations')}
            access={stationsAccess}
          >
            <NavBtn>
              <StationIcon
                fill={
                  currentPath.includes('stations')
                    ? colors.orange
                    : stationsAccess
                    ? 'white'
                    : 'gray'
                }
              />
            </NavBtn>
            <p selected={currentPath.includes('stations')}>STATIONS</p>
          </NavBtnWrapper>
        ))}

      {(process.env.REACT_FEATURE_DEPOTS === 'true' ||
        process.env.REACT_FEATURE_MENU_DEPOTS === 'true') &&
        (depotsAccess ? (
          <Link
            to="/dashboard/depots"
            onClick={() => {
              toggleSidebar();
            }}
          >
            <NavBtnWrapper
              selected={currentPath.includes('depots')}
              access={depotsAccess}
            >
              <NavBtn>
                <DepotIcon
                  fill={
                    currentPath.includes('depots')
                      ? colors.orange
                      : depotsAccess
                      ? 'white'
                      : 'gray'
                  }
                  stroke={
                    currentPath.includes('depots')
                      ? colors.orange
                      : depotsAccess
                      ? 'white'
                      : 'gray'
                  }
                />
              </NavBtn>
              <p selected={currentPath.includes('depots')}>DEPOTS</p>
            </NavBtnWrapper>
          </Link>
        ) : (
          <NavBtnWrapper selected={currentPath.includes('depots')} access={depotsAccess}>
            <NavBtn>
              <DepotIcon
                fill={
                  currentPath.includes('depots')
                    ? colors.orange
                    : depotsAccess
                    ? 'white'
                    : 'gray'
                }
              />
            </NavBtn>
            <p selected={currentPath.includes('depots')}>DEPOTS</p>
          </NavBtnWrapper>
        ))}

      {(process.env.REACT_FEATURE_ROUTE_PLANNER === 'true' ||
        process.env.REACT_FEATURE_MENU_ROUTE_PLANNER === 'true') &&
        (grpsAccess ? (
          <Link
            to="/dashboard/grps"
            onClick={() => {
              toggleSidebar();
            }}
          >
            <NavBtnWrapper selected={currentPath.includes('grps')} access={grpsAccess}>
              <NavBtn>
                <GRPSIcon
                  fill={
                    currentPath.includes('grps')
                      ? colors.orange
                      : grpsAccess
                      ? 'white'
                      : 'gray'
                  }
                  stroke={
                    currentPath.includes('grps')
                      ? colors.orange
                      : grpsAccess
                      ? 'white'
                      : 'gray'
                  }
                />
              </NavBtn>
              <p selected={currentPath.includes('grps')}>GRPS</p>
            </NavBtnWrapper>
          </Link>
        ) : (
          <NavBtnWrapper selected={currentPath.includes('grps')} access={grpsAccess}>
            <NavBtn>
              <GRPSIcon
                fill={
                  currentPath.includes('grps')
                    ? colors.orange
                    : grpsAccess
                    ? 'white'
                    : 'gray'
                }
              />
            </NavBtn>
            <p selected={currentPath.includes('grps')}>GRPS</p>
          </NavBtnWrapper>
        ))}

      {(process.env.REACT_FEATURE_EVENTS === 'true' ||
        process.env.REACT_FEATURE_MENU_EVENTS === 'true') &&
        (eventsAccess ? (
          <Link
            to="/dashboard/events"
            onClick={() => {
              toggleSidebar();
            }}
          >
            <NavBtnWrapper
              selected={currentPath.includes('events')}
              access={eventsAccess}
            >
              <NavBtn>
                <EventIcon
                  fill={
                    currentPath.includes('events')
                      ? colors.orange
                      : eventsAccess
                      ? 'white'
                      : 'gray'
                  }
                  stroke={
                    currentPath.includes('events')
                      ? colors.orange
                      : eventsAccess
                      ? 'white'
                      : 'gray'
                  }
                />
              </NavBtn>
              <p selected={currentPath.includes('events')}>EVENTS</p>
            </NavBtnWrapper>
          </Link>
        ) : (
          <NavBtnWrapper selected={currentPath.includes('events')} access={eventsAccess}>
            <NavBtn>
              <EventIcon
                fill={
                  currentPath.includes('events')
                    ? colors.orange
                    : eventsAccess
                    ? 'white'
                    : 'gray'
                }
              />
            </NavBtn>
            <p selected={currentPath.includes('events')}>EVENTS</p>
          </NavBtnWrapper>
        ))}

      {(process.env.REACT_FEATURE_SWITCH === 'true' ||
        process.env.REACT_FEATURE_MENU_SWITCH === 'true') &&
        (switchAccess ? (
          <Link
            to="/dashboard/switch"
            onClick={() => {
              toggleSidebar();
            }}
          >
            <NavBtnWrapper
              selected={currentPath.includes('switch')}
              access={switchAccess}
            >
              <NavBtn>
                <SwitchIcon
                  fill={
                    currentPath.includes('switch')
                      ? colors.orange
                      : switchAccess
                      ? 'white'
                      : 'gray'
                  }
                  stroke={
                    currentPath.includes('switch')
                      ? colors.orange
                      : switchAccess
                      ? 'white'
                      : 'gray'
                  }
                />
              </NavBtn>
              <p selected={currentPath.includes('switch')}>SWITCH</p>
            </NavBtnWrapper>
          </Link>
        ) : (
          <NavBtnWrapper selected={currentPath.includes('switch')} access={switchAccess}>
            <NavBtn>
              <SwitchIcon
                fill={
                  currentPath.includes('switch')
                    ? colors.orange
                    : switchAccess
                    ? 'white'
                    : 'gray'
                }
              />
            </NavBtn>
            <p selected={currentPath.includes('switch')}>SWITCH</p>
          </NavBtnWrapper>
        ))}

      {(process.env.REACT_FEATURE_BUDGET === 'true' ||
        process.env.REACT_FEATURE_MENU_BUDGET === 'true') && (
        <NavBtnWrapper access={budgetAccess} style={{cursor: 'not-allowed'}}>
          <NavBtn>
            <BudgetIcon fill="grey" />
          </NavBtn>
          <p style={{color: 'grey'}}>BUDGETS</p>
        </NavBtnWrapper>
      )}
    </div>
  );
};

const Layout = ({children}) => {
  const [layoutState, setLayoutState] = useContext(LayoutContext);
  const location = useLocation();

  const {
    backButton,
    callback,
    showSidebarOnSmallScreen,
    showMapOnSmallScreen,
    locationUrl,
  } = layoutState;
  const toggleSidebar = () =>
    setLayoutState({
      ...layoutState,
      showSidebarOnSmallScreen: !showSidebarOnSmallScreen,
    });
  const toggleMapView = () =>
    setLayoutState({
      ...layoutState,
      showMapOnSmallScreen: !showMapOnSmallScreen,
    });

  const currentPath = location?.pathname;

  return (
    <ApolloProvider client={client}>
      <Outer>
        <Navigation id="navigation" show={showSidebarOnSmallScreen}>
          <NavOuter>
            <NavInner>
              <FixedNav show={showSidebarOnSmallScreen}>
                {backButton ? (
                  <BackBtnWrapper>
                    <BackBtn
                      onClick={() => {
                        if (locationUrl == 'depo') {
                          navigate('/dashboard/depots');
                        } else if (locationUrl == 'graph') {
                          navigate('/dashboard/grps');
                        } else if (locationUrl == 'mileage') {
                          navigate('/dashboard/train/track');
                        } else if (locationUrl == 'station') {
                          navigate('/dashboard/stations');
                        } else if (locationUrl == 'passings') {
                          navigate('/dashboard/train/passings');
                        } else if (locationUrl == 'switch') {
                          navigate('/dashboard/switch');
                          callback && callback();
                        } else if (locationUrl == 'catenary') {
                          navigate('/dashboard/catenary');
                          callback && callback();
                        } else {
                          callback();
                          toggleSidebar();
                        }
                      }}
                      showBackButton={backButton.toString()}
                    >
                      <img src={BackArrow} alt="Go back" />
                    </BackBtn>
                  </BackBtnWrapper>
                ) : (
                  <Link to="/home">
                    <LogoWrapper>
                      <Logo src={CemitLogo} />
                    </LogoWrapper>
                  </Link>
                )}
                <FlexItems>
                  <NavItems currentPath={currentPath} toggleSidebar={toggleSidebar} />
                  {process.env.REACT_FEATURE_USER_PROFILE === 'true' && (
                    <ProfileItem
                      currentPath={currentPath}
                      toggleSidebar={toggleSidebar}
                    ></ProfileItem>
                  )}
                </FlexItems>
              </FixedNav>
            </NavInner>
          </NavOuter>
        </Navigation>
        <Main>{children}</Main>
      </Outer>
      <MobileFooter
        toggleMapView={toggleMapView}
        toggleSidebar={toggleSidebar}
        showSidebarOnSmallScreen={showSidebarOnSmallScreen}
        showMapOnSmallScreen={showMapOnSmallScreen}
      />
    </ApolloProvider>
  );
};

export default Layout;
