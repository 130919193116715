import {useState, useEffect} from 'react';

const hostName = `${process.env.REACT_APP_API}`;

export const doPost = async (
  url,
  options,
  newHostName = '',
  token = '',
  headers = {},
  isJwtTokenEnable = true,
) => {
  try {
    let fetchHeaders = {};

    if (isJwtTokenEnable) {
      fetchHeaders['JWT_TOKEN'] = token || localStorage.getItem('token');
    }

    fetchHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...fetchHeaders,
      ...headers,
    };

    const response = await fetch(`${newHostName || hostName}/${url}`, {
      method: 'GET',
      headers: fetchHeaders,
      ...options,
    });
    return response.json();
  } catch (error) {
    return {
      error,
    };
  }
};

export const doPostRequest = async (
  newHostName = '',
  url,
  options,
  token = '',
  headers = {},
  isJwtTokenEnable = true,
) => {
  try {
    let fetchHeaders = {};

    if (isJwtTokenEnable) {
      fetchHeaders['JWT_TOKEN'] = token || localStorage.getItem('token');
    }

    fetchHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...fetchHeaders,
      ...headers,
    };

    const response = await fetch(`${newHostName || hostName}/${url}`, {
      method: 'POST',
      headers: fetchHeaders,
      ...options,
    });
    return response.json();
  } catch (error) {
    return {
      error,
    };
  }
};

export const doFileRequest = async (
  newHostName = '',
  url,
  data,
  token = '',
  headers = {},
) => {
  try {
    const formData = new FormData();

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        formData.append(key, data[key]);
      }
    }

    const response = await fetch(`${newHostName || hostName}/${url}`, {
      method: 'POST',
      headers: {
        JWT_TOKEN: token || localStorage.getItem('token'),
        ...headers,
      },
      body: formData,
    });
    return response.json();
  } catch (error) {
    return {
      error,
    };
  }
};

export const doPut = async (
  url,
  options,
  newHostName = '',
  token = '',
  headers = {},
  isJwtTokenEnable = true,
) => {
  try {
    let fetchHeaders = {};

    if (isJwtTokenEnable) {
      fetchHeaders['JWT_TOKEN'] = token || localStorage.getItem('token');
    }

    fetchHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...fetchHeaders,
      ...headers,
    };

    const response = await fetch(`${newHostName || hostName}/${url}`, {
      method: 'PUT',
      headers: fetchHeaders,
      ...options,
    });
    return response.json();
  } catch (error) {
    return {
      error,
    };
  }
};

export const doGet = async (
  url,
  newHostName = '',
  signal = false,
  token = '',
  headers = {},
  isJwtTokenEnable = true,
) => {
  try {
    let fetchHeaders = {};

    if (isJwtTokenEnable) {
      fetchHeaders['JWT_TOKEN'] = token || localStorage.getItem('token');
    }

    fetchHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...fetchHeaders,
      ...headers,
    };

    const response = await fetch(`${newHostName || hostName}/${url}`, {
      headers: fetchHeaders,
      ...(signal && {signal}),
    });
    return response.json();
  } catch (error) {
    return {
      error,
    };
  }
};

export const doGetExt = async (
  url,
  {
    newHostName = '',
    signal = false,
    token = '',
    headers = {},
    isJwtTokenEnable = true,
    requestId = null,
  },
) => {
  try {
    let fetchHeaders = {};

    if (isJwtTokenEnable) {
      fetchHeaders['JWT_TOKEN'] = token || localStorage.getItem('token');
    }

    fetchHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...fetchHeaders,
      ...headers,
    };

    const response = await fetch(`${newHostName || hostName}/${url}`, {
      headers: fetchHeaders,
      ...(signal && {signal}),
    });
    if (response.status < 200 || response.status > 299) {
      throw new Error(
        `http request failed with: ${response.status}: ${response.statusText}`,
      );
    }
    const responseData = await response.json();
    return {responseData, requestId};
  } catch (error) {
    error.requestId = requestId;
    throw error;
  }
};

export const doGetRaw = async (url, body, signal = false, headers = {}) => {
  try {
    let fetchHeaders = {};

    // if (isJwtTokenEnable) {
    //   fetchHeaders['Authorization'] = token
    // }
    fetchHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...fetchHeaders,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      ...headers,
    };
    const response = await fetch(`${url}`, {
      method: 'POST',
      headers: fetchHeaders,
      body: JSON.stringify(body),
      ...(signal && {signal}),
    });
    return response.text();
  } catch (error) {
    console.log(error);
  }
};
export const doDelete = async (
  newHostName = '',
  url,
  token = '',
  headers = {},
  isJwtTokenEnable = true,
) => {
  try {
    let fetchHeaders = {};

    if (isJwtTokenEnable) {
      fetchHeaders['JWT_TOKEN'] = token || localStorage.getItem('token');
    }

    fetchHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...fetchHeaders,
      ...headers,
    };

    const response = await fetch(`${newHostName || hostName}/${url}`, {
      method: 'DELETE',
      headers: fetchHeaders,
    });

    return response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export const useGet = (url, newHostName = '', headers = {}) => {
  // eslint-disable-next-line no-restricted-globals
  // let token2 = localStorage.getItem('token');

  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);

  const abortController = new AbortController();

  const fetchOptions = {
    headers: {
      Accept: 'application/json',
      JWT_TOKEN: localStorage.getItem('token'),
      ...headers,
    },
    signal: abortController.signal,
  };

  const cleanUpFunction = () => {
    // console.log('call clean up function');
    if (fetching) {
      abortController.abort();
    }
  };

  useEffect(() => {
    fetch(`${newHostName || hostName}/${url}`, fetchOptions)
      .then((response) => {
        if (!response.ok) {
          throw Error(`${response.status} : ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        setFetching(false);
        setData(data);
        setError(null);
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          // console.log('fetch aborted');
        } else {
          setFetching(false);
          setData(null);
          setError(error.message);
        }
      });

    return cleanUpFunction;
  }, [url]);

  return {data, fetching, error};
};

export const toLocaleIsoString = (dateStart) => {
  let date = new Date(dateStart);
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
};
