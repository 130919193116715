import styled from '@emotion/styled';
import {colors} from './colors';
import {responsive} from './responsive';

export const Outer = styled.div`
  margin-bottom: 30px;
  max-width: 1500px;
  padding: 0 75px;
  margin: 0 auto;
  display: block;

  ${responsive.smAndLess} {
    padding: 0 1rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding: 50px;
  max-width: 800px;
  margin: ${(p) => (p.centerContent ? '0 auto' : '0 0 50px 0')};

  p {
    font-size: 18px;
    line-height: 1.8rem;
    color: ${colors.darkText};
  }
`;

export const Label = styled.div`
  color: ${colors.labelText};
  font-weight: 600;
  font-size: 12px;
  padding: ${(p) => (p.pad ? '25px' : '0')};
`;

const Wrapper = styled.div`
  background: ${colors.lightBlue};
  border-radius: 8px;
  margin-bottom: 15px;
  padding: ${(p) => (p.noPad ? '0px' : '25px')};
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.15);
  position: relative;
`;
export const Card = ({children, ...rest}) => <Wrapper {...rest}>{children}</Wrapper>;

export const Gradient = styled.div`
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  content: '';
  height: 30px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(0deg, rgba(72, 88, 99, 1) 0%, rgba(72, 88, 99, 0) 100%);
`;

export const Button = styled.button`
  width: 245px;
  height: 50px;
  background: #f6c90e;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 79px;
  font-weight: bold;
  font-size: 22px;
`;

export const Download = styled.div`
  float: right;
  button {
    margin: 15px 0px 0px 0px;
    border: solid 1px white;
    border-radius: 5px;
    padding: 5px;
    color: white;
    font-size: 12px;
  }
  @media (max-width: 1000px) {
    button {
      width: 90px;
      margin: 20px auto;
    }
  }
  @media (min-width: 200px) and (max-width: 400px) {
    float: none;
    button {
      display: grid;
      grid-template-columns: 1fr;
      margin: 10px auto;
      width: 200px;
      padding: 7px;
      transform: translateY(10px);
    }
  }
`;

export const StyledTable = styled.div`
  width: ${({fullWidth}) => (fullWidth ? '100%' : '65%')};
  overflow: ${({overlay}) => (overlay ? 'overlay' : 'auto')};
  padding: 0px 7px 7px 7px;
  ${({height}) =>
    height === null ? '' : height ? 'height:' + height + ';' : 'height: 300px;'}
  ${({minHeight}) => (minHeight ? 'min-height: ' + minHeight + ';' : '')}
  ${({maxHeight}) => (maxHeight ? 'max-height: ' + maxHeight + ';' : '')}

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: #3a4750;
  }
  &::-webkit-scrollbar-thumb {
    background: #919ba1;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #777;
  }

  .infinite-scroll-component {
    overflow: visible !important;
  }

  table {
    border-spacing: 0;
    width: 100%;

    thead tr:nth-of-type(1) th {
      background: #485863;
      position: sticky;
      top: 0;
      z-index: 10;
    }
    tbody > tr {
      &:hover {
        background-color: #3a4750;
      }
    }

    tr {
      height: 42.4px;
      :first-of-type {
        height: 20px;
        th {
          color: #b9c3ca;
          border-bottom: none;
          text-align: left;
        }
      }
      :nth-of-type(2) {
        th {
          color: #b9c3ca;
          border-bottom: none;
          text-align: left;
          input {
            width: 100%;
            color: #fff;
            background-color: #485863;
            border: 1px solid #fff;
            border-radius: 3px;
          }
        }
      }
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 2px solid #3a4750;
      font-size: ${({size = 12}) => `${size}px`};
      white-space: nowrap;
      :last-child {
        border-right: 0;
      }
    }
  }
`;
