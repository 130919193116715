import styled from '@emotion/styled';
import {colors, responsive, Button as B} from 'ui';
import emailSvgIcon from 'assets/icons/email.svg';
import passwordSvgIcon from 'assets/icons/password.svg';

export const Outer = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100%;
  background-color: ${colors.darkBlue};
  width: 100%;
  * {
    box-sizing: border-box;
  }
`;

export const Logo = styled.div`
  position: fixed;
  left: 45px;
  top: 45px;
  width: 180px;
  img {
    width: 100%;
  }
`;

export const AuthFormInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    text-shadow: 2px 1px rgba(0, 0, 0, 0.15);
    padding: 20px 10px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
  }

  p {
    text-shadow: 2px 1px rgba(0, 0, 0, 0.15);
    padding: 0px 10px 20px 10px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }

  input {
    margin: 5px;
  }
`;

export const AuthForm = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  justify-items: center;

  ${responsive.smAndLess} {
    width: 100%;
    z-index: 1;
  }
`;

export const AuthContent = styled.div`
  /* background: #fff; */
  padding: 70px;
  border-radius: 5px;
  position: relative;
  /* box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.15); */
  display: flex;
  width: 500px;
  flex-direction: column;

  ${responsive.smAndLess} {
    width: 100%;
    padding: 0px;
  }
`;
export const Feedback = styled.div`
  border-radius: 5px;
  color: #fff;
  padding: 10px;
  width: 340px;
  position: relative;
  bottom: 0;
  font-size: 12px;
  font-weight: 600;
  margin: 10px 5px;
  background: ${(props) => (props.status != 200 ? `${colors.red}` : `${colors.green}`)};
  @media (max-width: 400px) {
    width: calc(100% - 10px);
  }
`;
export const ImageWrapper = styled.div`
  width: 50%;
  position: fixed;
  right: 0;
  bottom: 0;
  img {
    max-width: 100%;
  }

  ${responsive.smAndLess} {
    width: 100%;
    position: absolute;
    z-index: 0;
    opacity: 0.2;
  }
`;

export const StyledInput = styled.input`
  outline: none;
  border-radius: 5px;
  background: #fff;
  border: 0px solid ${colors.blackish};
  padding: 15px 25px;
  /* font-size: 16px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  padding: 15px 20px;
  border-radius: 41px;
  background-image: ${(props) =>
    props.id === 'email' ? `url(${emailSvgIcon})` : `url(${passwordSvgIcon})`};
  background-position: ${(props) => (props.id === 'email' ? '15px 20px' : '15px 15px')};
  text-indent: 25px;
  background-repeat: no-repeat;
  padding-left: 30px; */
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
`;

export const Button = styled(B)`
  font-size: 16px;
  padding: 12px 25px;
  &:disabled {
    background: #fafafa;
    opacity: 0.8;
  }
`;

export const Loader = styled.span`
  display: ${(props) => (props.show ? `block` : `none`)};
  border: 5px solid #fff;
  border-radius: 50%;
  border-top: 5px solid #f6c90e;
  width: 40px;
  height: 40px;
  align-self: center;
  margin-left: 10px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
