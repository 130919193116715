import React, {Suspense, lazy, useState} from 'react';
import {render} from 'react-dom';
import {Router} from '@reach/router';
import Loading from 'components/loading';
import Layout from 'components/layout';
import {LayoutContextProvider} from 'contexts/layout-context';
import {DashboardContextProvider} from 'contexts/dashboard-context';
import {UserContextProvider} from 'contexts/user-context';
import {ToastContainer} from 'react-toastify';
import ErrorBoundary from './error-boundary';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {CrossAppLink} from 'components/cross-app-link';
import {AuthenticateUser, VisualizerAccess} from './pages/auth';
import 'regenerator-runtime/runtime';
import 'react-toastify/dist/ReactToastify.min.css';
import GraphPdfTron from './pages/dashboard/grps/graph-pdftron';

const DashboardIndex = lazy(() => import('./pages/dashboard/index'));
const DashboardTrain = lazy(() => import('./pages/dashboard/train'));
const DashboardCatenary = lazy(() => import('./pages/dashboard/catenary'));
const AdminPage = lazy(() => import('./pages/admin'));
const EventsPage = lazy(() => import('./pages/dashboard/events'));
const SwitchPage = lazy(() => import('./pages/dashboard/switch'));
const IndexPage = lazy(() => import('./pages/auth'));
const DashboardProfile = lazy(() => import('./pages/dashboard/profile'));
const ProtectedRoute = lazy(() => import('./pages/protected-route'));
const DashboardStations = lazy(() => import('./pages/dashboard/stations'));
const DashboardDepots = lazy(() => import('./pages/dashboard/depots'));
const DashboardGRPSCentrals = lazy(() => import('./pages/dashboard/grps/routes'));
const DashboardGRPS = lazy(() => import('./pages/dashboard/grps'));
const DashboardDepo = lazy(() => import('./pages/dashboard/depots/depo'));
const DashboardStation = lazy(() => import('./pages/dashboard/stations/station'));
const app_release = process.env.APP_RELEASE || 'dev';

const cleanUpLocalStoreLimits = () => window.localStorage.removeItem('limits');

if (process.env.REACT_FEATURE_SENTRY === 'true' && process.env.REACT_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    release: process.env.NODE_ENV + '-v0',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05
  });
}

const DashboardLayout = ({children}) => (
  <DashboardContextProvider>
    <Layout>{children}</Layout>
  </DashboardContextProvider>
);

/**
 * The Legacy apps like track, depots, and the GRPS app
 * @return {Element}
 * @constructor
 */
const LegacyApp = () => {
  // Preserve the Rails country and trackName when the user goes to a different application
  // This could instead be stored in local storage with useLocalStorage (https://usehooks-ts.com/react-hook/use-local-storage)
  const [visualizerCountry, setVisualizerCountry] = useState(undefined);
  const [visualizerTrackName, setVisualizerTrackName] = useState(undefined);
  // Stores the chosen country and track in the current session
  const visualizerCountryAndTrackProps = {
    visualizerCountry,
    setVisualizerCountry,
    visualizerTrackName,
    setVisualizerTrackName
  };

  return (
    <ErrorBoundary>
      <UserContextProvider>
        <AuthenticateUser>
          <LayoutContextProvider>
            <VisualizerAccess>
              <Suspense fallback={<Loading />}>
                <Router>
                  <ProtectedRoute path="/">
                    <CrossAppLink path="home" productPath="home" />

                    <DashboardLayout path="dashboard">
                      {process.env.REACT_FEATURE_TRACK === 'true' && (
                        <DashboardIndex
                          {...{
                            path: 'index/:countryName/overview',
                            view: 'country-overview',
                            ...visualizerCountryAndTrackProps
                          }}
                        />
                      )}
                      {process.env.REACT_FEATURE_TRACK === 'true' && (
                        <DashboardIndex
                          {...{
                            path: 'index/:countryName/:trackName',
                            view: 'track',
                            ...visualizerCountryAndTrackProps
                          }}
                        />
                      )}
                      {process.env.REACT_FEATURE_TRACK === 'true' && (
                        <DashboardIndex
                          {...{
                            path: 'index/:countryName',
                            view: 'country',
                            ...visualizerCountryAndTrackProps
                          }}
                        />
                      )}
                      {process.env.REACT_FEATURE_TRACK === 'true' && (
                        <DashboardIndex
                          {...{
                            path: 'index',
                            view: 'index',
                            preserveCountryAndTrack: true,
                            ...visualizerCountryAndTrackProps
                          }}
                        />
                      )}

                      {process.env.REACT_FEATURE_TRAIN === 'true' && (
                        <CrossAppLink path="train/*" productPath="train" />
                      )}

                      {process.env.REACT_FEATURE_TRAIN_WHEEL_TEMP === 'true' && (
                        <DashboardTrain path="train/*" />
                      )}

                      {process.env.REACT_FEATURE_CATENARY === 'true' && (
                        <DashboardCatenary path="catenary/:countryName/:trackName" />
                      )}
                      {process.env.REACT_FEATURE_CATENARY === 'true' && (
                        <DashboardCatenary path="catenary/:countryName" />
                      )}
                      {process.env.REACT_FEATURE_CATENARY === 'true' && (
                        <DashboardCatenary path="catenary" />
                      )}

                      {process.env.REACT_FEATURE_STATIONS === 'true' && (
                        <DashboardStation path="stations/:stationName/*" />
                      )}
                      {process.env.REACT_FEATURE_STATIONS === 'true' && (
                        <DashboardStations path="stations" />
                      )}

                      {process.env.REACT_FEATURE_DEPOTS === 'true' && (
                        <DashboardDepo path="depots/:depotName/*" />
                      )}
                      {process.env.REACT_FEATURE_DEPOTS === 'true' && (
                        <DashboardDepots path="depots" />
                      )}

                      <DashboardGRPSCentrals path="grps/centrals/:centralId" />
                      <GraphPdfTron path="grps/:graph/:route_id" />
                      <DashboardGRPS path="grps/" />
                      <DashboardGRPS path="grps/centrals" />

                      {process.env.REACT_FEATURE_ADMIN === 'true' && (
                        <AdminPage path="admin" />
                      )}

                      {process.env.REACT_FEATURE_EVENTS === 'true' && (
                        <EventsPage path="events" />
                      )}

                      {process.env.REACT_FEATURE_SWITCH === 'true' && (
                        <SwitchPage path="switch/:countryName/:locationName/:sensorId" />
                      )}
                      {process.env.REACT_FEATURE_SWITCH === 'true' && (
                        <SwitchPage path="switch/:countryName/:locationName" />
                      )}
                      {process.env.REACT_FEATURE_SWITCH === 'true' && (
                        <SwitchPage path="switch/:countryName" />
                      )}
                      {process.env.REACT_FEATURE_SWITCH === 'true' && (
                        <SwitchPage path="switch" />
                      )}

                      {process.env.REACT_FEATURE_USER_PROFILE === 'true' && (
                        <DashboardProfile path="profile" />
                      )}
                    </DashboardLayout>
                  </ProtectedRoute>
                  <IndexPage path="/" />
                </Router>
              </Suspense>
            </VisualizerAccess>
          </LayoutContextProvider>
        </AuthenticateUser>
      </UserContextProvider>
    </ErrorBoundary>
  );
};

let App = document.getElementById('app');

function renderApp() {
  render(
    <>
      <LegacyApp />
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        pauseOnHover={false}
        position="top-center"
        theme="colored"
        limit={1}
        role="alert"
      />
    </>,
    App
  );
}

renderApp();

if (module.hot) {
  module.hot.accept();
}

if ('serviceWorker' in navigator) {
  if (process.env.REACT_FEATURE_SERVICEWORKER === 'true') {
    if (DEBUG) {
      console.log('ServiceWorker installation started');
    }
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(new URL('./sw.js', import.meta.url))
        .then((registration) => {
          if (DEBUG && console && console.log) {
            console.log('ServiceWorker registered: ', registration);
          }
        })
        .catch((registrationError) => {
          if (DEBUG && console && console.log) {
            console.log('ServiceWorker registration failed: ', registrationError);
          }
        });
    });
  } else {
    let wasRegistered = false;
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (const registration of registrations) {
        registration.unregister();
        wasRegistered = true;
      }
    });
    if (DEBUG && console && console.log && wasRegistered) {
      console.log('ServiceWorker removed');
    }
  }
}
