import styled from '@emotion/styled';
import {colors} from 'ui';

export const DepotsOuter = styled.div`
  background: ${colors.lightBlue};
  border-radius: 8px;
  margin: 10vh auto 10vh auto;
  padding: ${(p) => (p.noPad ? '0px' : '25px')};
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.15);
  height: 80vh;
  width: 50%;
  @media (max-width: 1300px) {
    width: 70%;
  }
  @media (max-width: 700px) {
    width: 90%;
  }
  h1 {
    font-size: 30px;
    text-align: center;
    padding: 5px 0px 5px 0px;
    color: white;
  }
  p {
    color: white;
  }
  ul {
    height: calc(80vh - 135px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: #3a4750;
    }
    &::-webkit-scrollbar-thumb {
      background: #919ba1;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #777;
    }
  }
`;

export const DepoListItem = styled.li`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 700px) {
    align-items: end;
    flex-direction: column;
    input {
      margin-top: 10px;
    }
  }
  color: white;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid ${colors.darkBlue};
  p {
    font-size: 20px;
    margin-right: auto;
  }
  .link {
    color: ${colors.orange};
    border: 2px solid ${colors.orange};
    padding: 7px;
    border-radius: 3px;
    line-height: normal;
  }
`;

// export const Test = styled.div`
//   display: grid;
//   grid-template-areas: 'header h1 input';
//   grid-template-columns: 1fr auto 1fr;
//   h1 {
//     grid-area: h1;
//     text-align: center;
//   }
//   div {
//     grid-area: input;
//     justify-items: end;
//     place-self: end;
//   }
// `;

export const UploadButtons = styled.div`
  button {
    color: white;
    border: 2px solid white;
    padding: 7px;
    border-radius: 3px;
    font-size: 16px;
  }
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${(p) => (p.margin ? '10px' : '0px')};
  input {
    width: ${(p) => (p.small ? '155px' : p.selectedFiles ? '130px' : '170px')};
  }
  input[type='file']::file-selector-button {
    font-size: 16px;
    background-color: transparent;
    border-radius: 3px;
    padding: 7px;
    color: white;
    border: 2px solid white;
  }
  input[type='file']::-webkit-file-upload-button {
    font-size: 16px;
    background-color: transparent;
    border-radius: 3px;
    padding: 7px;
    color: white;
    border: 2px solid white;
  }
`;

export const Error = styled.div`
  background-color: #f8d7da;
  border: 2px solid transparent;
  p {
    color: #842029;
  }

  // border-color: #f5c2c7;
  position: absolute;
  top: 0;
  left: 30%;
  width: 40%;
  text-align: left;
  border-radius: 10px;
  padding: 10px;

  #removeError {
    float: right;
    margin-right: 10px;
  }
  #removeError-x {
    font-size: 30px;
    float: right;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Circle = styled.span`
  height: 36px;
  width: 36px;
  display: table-cell !important;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  font-family:
    adobe-clean,
    'Source Sans Pro',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-weight: 600;
  color: ${(p) => p.color};
  // background: ${(p) => p.bgcolor};
  background: rgb(173, 223, 255);
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999 !important;
  background-color: rgba(0, 0, 0, 0.6);
`;
export const PopupBox = styled.div`
  min-width: 300px;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 12px 14px 25px;
  border-radius: 14px;
  font-size: 1.2rem;
  font-weight: 600;
`;

export const DepotsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled.button`
  color: ${colors.orange};
  border: 2px solid ${colors.orange};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 1rem;
`;

export const Label = styled.h2`
  color: ${colors.textPrimary};
  text-align: ${({align = 'left'}) => align};
  font-size: ${({size = 16}) => size + 'px'};
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 32px;
`;

export const Info = styled.div`
  width: ${({fullWidth}) => (fullWidth ? '100%' : '55%')};
  margin-right: 15px;
`;

export const ContentCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
