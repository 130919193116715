import styled from '@emotion/styled';

export const DepoHeader = styled.div`
  display: flex;
  h1 {
    padding: 30px 25px;
    font-size: 30px;
    color: white;
  }
  .users {
    display: flex;
    color: white;
    margin-left: auto;
    align-items: center;
    // justify-content: center;
    // margin-top: 25.7px;
    margin-right: 30px;
    div {
      margin: 5px;
    }
  }
`;

export const Circle = styled.span`
  height: 36px;
  width: 36px;
  display: table-cell !important;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  font-family:
    adobe-clean,
    'Source Sans Pro',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-weight: 600;
  color: ${(p) => p.color};
  // background: ${(p) => p.bgcolor};
  background: rgb(173, 223, 255);
`;
