import {useState, createContext} from 'react';
export const DashboardContext = createContext();

/**
 * State manager for rail-cant date, called each time the state is meant to change
 * @param {Object} obj - Parameters
 * @param {Object} obj.dashboardState - The current dashboard state
 * @param {string} obj.railCantState - The new rail-cant state
 * @return {object} - The new dashboard state
 */
export const updateRailCantState = ({
  dashboardState,
  railCantState,
  trackFaultAlertState,
}) => {
  const prevHeightState = dashboardState.railCantState;
  // const prevAlertState = dashboardState.railCantAlertState;
  let newState = {
    ...dashboardState,
  };
  if (railCantState !== undefined) {
    newState.railCantState = railCantState;
    // Handles transitions to and from load states
    if (prevHeightState !== 'load' && railCantState === 'load') {
      newState.railCantLoadRequest = true;
    } else if (prevHeightState === 'load' && railCantState !== 'load') {
      newState.railCantLoadRequest = false;
    }
  }

  if (trackFaultAlertState !== undefined) {
    newState.trackFaultAlertState = trackFaultAlertState;
  }
  return newState;
};

/**
 * State manager for rail-cant surface data, called each time the state is meant to change
 * @param {Object} obj - Parameters
 * @param {Object} obj.dashboardState - The current dashboard state
 * @param {string} obj.railCantSurfaceState - The new rail-cant surface state
 * @return {object} - The new dashboard state
 */
export const updateRailCantSurfaceState = ({dashboardState, railCantSurfaceState}) => {
  let newState = {
    ...dashboardState,
    railCantSurfaceState: railCantSurfaceState,
  };
  return newState;
};

export const DashboardContextProvider = (props) => {
  const [dashboardState, setDashboardState] = useState({
    mapContext: null,
    /**
     * Specifies how to zoom on the map when no stored coordinates are found
     * Can be one of:
     * - world - Zoom to see entire world
     * - country - Zoom to cover country
     * - region - Zoom to cover region
     * - track - Zoom to cover track
     * - section - Zoom to cover track section
     *
     * @type {string}
     */
    mapZoomMode: 'world',
    clusters: null,
    reconstructedClusters: null,
    errors: null,
    reconstructedErrors: null,
    errorsCompare: null,
    reconstructedErrorsCompare: null,
    track: null,
    trackCompare: null,
    reconstructedTrack: null,
    reconstructedTrackCompare: null,
    availableTrackDataSets: null,
    /**
     * The current year/week that has been chosen/set.
     * @type {?string}
     */
    activeRailCantYearWeek: null,
    contextActiveTrack: null,
    /**
     * Which part of the entire track has been selected, only used for for Rail Cant.
     * @type {?string}
     */
    activeSectionId: null,
    /**
     * State for the rail-cant load process
     * States:
     * - initial - Initial state, waiting for load request
     * - load - Start loading data
     * - loading - Data is loading
     * - loaded - Data has been loaded
     * - ready - Slider is ready for use
     * - error - Error occured during loading
     * - aborted - Request was aborted
     * - no_data - No data is available
     *
     * @type {string}
     */
    railCantState: 'initial',
    /**
     * State for the track-fault alert load process
     * States:
     * - initial - Initial state, waiting for load request
     * - load - Start loading data
     * - loading - Data is loading
     * - loaded - Data has been loaded
     * - ready - Slider is ready for use
     * - error - Error occured during loading
     * - aborted - Request was aborted
     * - no_data - No data is available
     *
     * @type {string}
     */
    trackFaultAlertState: 'initial',
    /**
     * A special state for the rail-cant loading process, it is only used to
     * optimize the useEffect() by reducing how often it needs to be triggered.
     * This should not be set manually but must be handled by the updateRailCantState() function
     *
     * @type {boolean}
     */
    railCantLoadRequest: false,
    /**
     * Current abort controller for rail-cant fetch.
     *
     * @type {?AbortController}
     */
    railCantAbortController: null,
    /**
     * Current abort controller for track-fault alert fetch.
     *
     * @type {?AbortController}
     */
    trackFaultAlertAbortController: null,
    /**
     * State for the rail-cant surface load process
     * States:
     * - initial - Initial state, waiting for load request
     * - load - Start loading data
     * - loading - Data is loading
     * - ready - Data has been loaded and ready for use
     * - no_data - No data is available
     *
     * @type {string}
     */
    railCantSurfaceState: 'initial',
    /**
     * State for the catenary load process
     * States:
     * - initial - Initial state, waiting for load request
     * - load - Start loading data
     * - loading - Data is loading
     * - ready - Data has been loaded and ready for use
     *
     * @type {string}
     */
    catenaryState: 'initial',
    downloadAvailable: false,
    /**
     * Determines the current view mode on the map
     * For acceleration:
     * acc-xyz, acc-x, acc-y, acc-z
     * For catenary:
     *
     * @type {?string}
     **/
    viewMode: null,
    bcObj: null,
    deviceMAC: null,
    updatingTimeRange: true,
    fromWeek: null,
    toWeek: null,
  });

  return (
    <DashboardContext.Provider value={[dashboardState, setDashboardState]}>
      {props.children}
    </DashboardContext.Provider>
  );
};
