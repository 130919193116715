import React from 'react';
import {ScreenWrapper} from 'ui/shared-styles';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error({error, errorInfo});
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ScreenWrapper>
          <p>
            Oops, something went wrong. Try reloading page or check if url is wrong
            {/* Check browser console */}
          </p>
          <button onClick={() => location.reload()}>Click to reload page</button>{' '}
        </ScreenWrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
