import {doGet} from './helpers';
import {
  getApiTrackIds,
  getRailCantSectionByIdentifier,
  getTrackByIdentifier,
} from 'assets/tracks';

export const getNormalizedName = (name) => {
  name = name.replace('ø', 'oe');
  return name.toLowerCase().split(' ').join('_');
};

export const getTrackData = (trackIdentifier) => {
  if (!trackIdentifier) return;
  const trackDefinition = getTrackByIdentifier(trackIdentifier);
  if (!trackDefinition || !trackDefinition.apiTrackId) return;

  doGet(`api/v2/tracks/${trackDefinition.apiTrackId}/status/`);
};

export const getTrackDataFromWeek = (
  trackIdentifier,
  week,
  fromKm = null,
  toKm = null,
) => {
  if (!trackIdentifier) return;
  const trackDefinition = getTrackByIdentifier(trackIdentifier);
  if (!trackDefinition || !trackDefinition.apiTrackId) return;

  if (!fromKm && !toKm) {
    return doGet(`api/v2/tracks/${trackDefinition.apiTrackId}/status?week=${week}`);
  } else {
    return doGet(
      `api/v2/tracks/${trackDefinition.apiTrackId}/status?kmstart=${fromKm}&kmstop=${toKm}&week=${week}`,
    );
  }
};

export const getErrorsInWeek = (trackIdentifier, week, fromKm = null, toKm = null) => {
  if (!trackIdentifier) return;
  const trackDefinition = getTrackByIdentifier(trackIdentifier);
  if (!trackDefinition || !trackDefinition.apiTrackId) return;

  if (!fromKm && !toKm) {
    return doGet(`api/v2/tracks/${trackDefinition.apiTrackId}/errors?week=${week}`);
  } else {
    return doGet(
      `api/v2/tracks/${trackDefinition.apiTrackId}/errors?kmstart=${fromKm}&kmstop=${toKm}&week=${week}`,
    );
  }
};

export const getAvailableDataSetsForTrack = (trackIdentifier) => {
  if (!trackIdentifier) return;
  const trackDefinition = getTrackByIdentifier(trackIdentifier);
  if (!trackDefinition || !trackDefinition.apiTrackId) return;

  return doGet(`api/v2/tracks/${trackDefinition.apiTrackId}/available`);
};

export const getRailCantAvailableWeeks = ({
  trackIdentifier,
  sectionId = null,
  callFor = {},
}) => {
  const trackDefinition = getTrackByIdentifier(trackIdentifier);
  if (trackDefinition === null) {
    console.log(
      `Track ${trackIdentifier} is not a known track, cannot fetch available data`,
    );
    return new Promise((resolve) => {
      resolve(null);
    });
  }
  const sectionDefinition = getRailCantSectionByIdentifier(trackDefinition, sectionId);
  if (sectionDefinition === null) {
    console.log(
      `Track ${trackIdentifier} does not have section ${sectionId}, cannot fetch available data`,
    );
    return new Promise((resolve) => {
      resolve(null);
    });
  }
  const apiTrackId = sectionDefinition?.apiTrackId;
  const apiTrackDirection = trackDefinition?.apiTrackDirection ?? null;
  const apiTrackGroups = trackDefinition?.apiTrackGroups ?? null;

  const directionParam = apiTrackDirection ? '&direction=' + apiTrackDirection : '';
  const trackGroupParam = apiTrackGroups
    ? apiTrackGroups.map((v) => '&track_group=' + v).join('')
    : '';

  let availableWeek = `api/v1/digital-twin/tracks/available-weeks?track=${apiTrackId}${directionParam}${trackGroupParam}`;

  if (callFor?.catenary) {
    availableWeek += `&catenary=true`;
  }

  if (callFor?.width) {
    availableWeek += `&width=true`;
  }

  return doGet(availableWeek, process.env.REACT_APP_EVENT_API, false, '');
};

export const getSimplifiedTracks = (apiIds) => {
  if (!apiIds) {
    apiIds = getApiTrackIds();
  }
  if (!apiIds) {
    return;
  }
  let trackParam = '';
  let separator = '?';
  for (let i = 0; i < apiIds.length; ++i) {
    trackParam += `${separator}trackName=${apiIds[i]}`;
    separator = '&';
  }
  return doGet(`api/v2/tracks/myLines${trackParam}`);
};

/**
 * Returns the URL for authentication API
 */
export const getAuthApiUrl = () => {
  return process.env.REACT_APP_AUTH_API;
};

export const getMileage = (trackIdentifier, from, to) => {
  if (!trackIdentifier) return;
  const trackDefinition = getTrackByIdentifier(trackIdentifier);
  if (!trackDefinition || !trackDefinition.apiTrackId) return;

  return doGet(
    `api/v2/tracks/${trackDefinition.apiTrackId}/mileage?from_dt=${from}T00:00:00&to_dt=${to}T23:59:59`,
  );
};
