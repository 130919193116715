export const updateTools = [
  'AnnotationCreateTextHighlight',
  'AnnotationCreateTextUnderline',
  'AnnotationCreateTextSquiggly',
  'AnnotationCreateTextStrikeout',
  'AnnotationCreateTextHighlight2',
  'AnnotationCreateTextUnderline2',
  'AnnotationCreateTextSquiggly2',
  'AnnotationCreateTextStrikeout2',
  'AnnotationCreateTextHighlight3',
  'AnnotationCreateTextUnderline3',
  'AnnotationCreateTextSquiggly3',
  'AnnotationCreateTextStrikeout3',
  'AnnotationCreateTextHighlight4',
  'AnnotationCreateTextUnderline4',
  'AnnotationCreateTextSquiggly4',
  'AnnotationCreateTextStrikeout4',
];

export const disableTools = [
  'AnnotationCreateRectangle',
  'AnnotationCreateRectangle2',
  'AnnotationCreateRectangle3',
  'AnnotationCreateRectangle4',
  'AnnotationCreateArrow',
  'AnnotationCreateArrow2',
  'AnnotationCreateArrow3',
  'AnnotationCreateArrow4',
  'AnnotationCreateSticky',
  'AnnotationCreateSticky2',
  'AnnotationCreateSticky3',
  'AnnotationCreateSticky4',
  'AnnotationCreatePolygonCloud',
  'AnnotationCreatePolygonCloud2',
  'AnnotationCreatePolygonCloud3',
  'AnnotationCreatePolygonCloud4',
  'AnnotationCreateFreeHand',
  'AnnotationCreateFreeHand2',
  'AnnotationCreateFreeHand3',
  'AnnotationCreateFreeHand4',
  'AnnotationCreateFreeHandHighlight',
  'AnnotationCreateFreeHandHighlight2',
  'AnnotationCreateFreeHandHighlight3',
  'AnnotationCreateFreeHandHighlight4',
];

export const colors = [
  [0, 0, 255],
  [255, 0, 0],
  [255, 165, 79],
  [255, 255, 0],
  // [254, 211, 73],
];

export const setStyles = [
  'AnnotationCreateFreeText',
  'AnnotationCreateFreeText2',
  'AnnotationCreateFreeText3',
  'AnnotationCreateFreeText4',
];

export const thickLines = [0, 1, 4, 5];

export const setStylesLines = [
  'AnnotationCreatePolyline',
  'AnnotationCreatePolyline2',
  'AnnotationCreatePolyline3',
  'AnnotationCreatePolyline4',
  'AnnotationCreateLine',
  'AnnotationCreateLine2',
  'AnnotationCreateLine3',
  'AnnotationCreateLine4',
  // 'AnnotationCreateFreeHand',
  // 'AnnotationCreateFreeHand2',
  // 'AnnotationCreateFreeHand3',
  // 'AnnotationCreateFreeHand4',
];

export const addText = (annotationManager, Annotations, parentAnnotation) => {
  const coords = {
    x: parentAnnotation.getX() + parentAnnotation.getWidth() / 2 - 8,
    y: parentAnnotation.getY() + parentAnnotation.getHeight() / 2 - 10,
  };

  console.log('add text runs');
  const freeText = new Annotations.FreeTextAnnotation();
  freeText.PageNumber = 1;
  freeText.X = coords.x;
  freeText.Y = coords.y;
  freeText.Width = 16;
  freeText.Height = 10;
  freeText.StrokeColor = new Annotations.Color(255, 255, 255);
  freeText.StrokeThickness = 0;
  freeText.setAutoSizeType('AUTO');

  freeText.setContents('Tx -');
  freeText.FontSize = '8pt';

  annotationManager.groupAnnotations(parentAnnotation, [freeText]);
  annotationManager.addAnnotation(freeText, {autoFocus: false});
  annotationManager.redrawAnnotation(freeText);
};

export const addDispositionTool = (instance) => {
  const {Annotations, annotationManager, Tools, documentViewer} = instance.Core;
  class DispositionAnnotation extends Annotations.CustomAnnotation {
    constructor() {
      super('disposition'); // provide the custom XFDF element name
      this.Subject = 'Disposition';
      // console.log('trianlge constructor runs');
      // console.log('this constructor', this);
      // addText(this);
    }

    draw(ctx, pageMatrix) {
      // the setStyles function is a function on markup annotations that sets up
      // certain properties for us on the canvas for the annotation's stroke thickness.
      this.setStyles(ctx, pageMatrix);
      // first we need to translate to the annotation's x/y coordinates so that it's
      // drawn in the correct location
      // console.log('this draw', this);

      ctx.translate(this.X, this.Y);
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(0, this.Height);
      ctx.moveTo(0, this.Height / 2);
      ctx.lineTo(this.Width, this.Height / 2);
      ctx.lineTo(this.Width, 0);
      ctx.lineTo(this.Width, this.Height);
      ctx.moveTo(this.Width, this.Height / 2);
      ctx.closePath();
      ctx.fill();

      // ctx.fillText('amazing', this.Width / 2, this.Height / 2);
      // ctx.strokeText('test');

      ctx.strokeStyle = 'rgb(255, 0, 0)';
      ctx.stroke();
    }
  }

  // this is necessary to set the elementName before instantiation
  DispositionAnnotation.prototype.elementName = 'disposition';
  annotationManager.registerAnnotationType(
    DispositionAnnotation.prototype.elementName,
    DispositionAnnotation,
  );

  class DispositionCreateTool extends Tools.GenericAnnotationCreateTool {
    constructor(documentViewer) {
      // DispositionAnnotation is the class (function) for our annotation we defined previously
      super(documentViewer, DispositionAnnotation);
    }
  }

  const dispositionToolName = 'AnnotationCreateDisposition';

  const dispositionTool = new DispositionCreateTool(documentViewer);
  instance.UI.registerTool(
    {
      toolName: dispositionToolName,
      toolObject: dispositionTool,
      buttonImage: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <line x1="1" y1="7" x2="1" y2="17" style="stroke:currentColor;stroke-width:2" />
      <line x1="1" y1="12" x2="20" y2="12" style="stroke:currentColor;stroke-width:2" />
      <line x1="20" y1="7" x2="20" y2="17" style="stroke:currentColor;stroke-width:2" />
        </svg>`,
      buttonName: 'dispositionToolButton',
      tooltip: 'Disposition',
    },
    DispositionAnnotation,
  );

  instance.UI.setHeaderItems((header) => {
    header.getHeader('toolbarGroup-Annotate').get('shapeToolGroupButton').insertBefore({
      type: 'toolButton',
      toolName: dispositionToolName,
    });
  });
};

export const addRedRectangleTool = (instance) => {
  const {Annotations, annotationManager, Tools, documentViewer} = instance.Core;
  class RedRectangleAnnotation extends Annotations.CustomAnnotation {
    constructor() {
      super('redrectangle'); // provide the custom XFDF element name
      this.Subject = 'RedRectangle';
      // console.log('trianlge constructor runs');
      // console.log('this constructor', this);
      // addText(this);
    }

    draw(ctx, pageMatrix) {
      // the setStyles function is a function on markup annotations that sets up
      // certain properties for us on the canvas for the annotation's stroke thickness.
      this.setStyles(ctx, pageMatrix);
      // first we need to translate to the annotation's x/y coordinates so that it's
      // drawn in the correct location
      // console.log('this draw', this);

      ctx.translate(this.X, this.Y);
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(0, this.Height);
      ctx.lineTo(this.Width, this.Height);
      ctx.lineTo(this.Width, 0);
      // ctx.moveTo(0, this.Height / 2);
      // ctx.lineTo(this.Width, this.Height / 2);
      // ctx.lineTo(this.Width, 0);
      // ctx.lineTo(this.Width, this.Height);
      // ctx.moveTo(this.Width, this.Height / 2);
      ctx.closePath();
      ctx.fill();

      // ctx.fillText('amazing', this.Width / 2, this.Height / 2);
      // ctx.strokeText('test');

      ctx.strokeStyle = 'rgb(255, 0, 0)';
      ctx.stroke();
    }
  }

  // this is necessary to set the elementName before instantiation
  RedRectangleAnnotation.prototype.elementName = 'redrectangle';
  annotationManager.registerAnnotationType(
    RedRectangleAnnotation.prototype.elementName,
    RedRectangleAnnotation,
  );

  class DispositionCreateTool extends Tools.GenericAnnotationCreateTool {
    constructor(documentViewer) {
      // DispositionAnnotation is the class (function) for our annotation we defined previously
      super(documentViewer, RedRectangleAnnotation);
    }
  }

  const dispositionToolName = 'AnnotationCreateRedRectangle';

  const dispositionTool = new DispositionCreateTool(documentViewer);
  instance.UI.registerTool(
    {
      toolName: dispositionToolName,
      toolObject: dispositionTool,
      buttonImage: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
            viewBox="0 0 24 24" fill="currentColor">
            <line x1="1" y1="1" x2="20" y2="1" style="stroke:red;stroke-width:2" />
            <line x1="20" y1="1" x2="20" y2="17" style="stroke:red;stroke-width:2" />
            <line x1="20" y1="17" x2="1" y2="17" style="stroke:red;stroke-width:2" />
            <line x1="1" y1="17" x2="1" y2="1" style="stroke:red;stroke-width:2" />
        </svg>`,
      buttonName: 'redrectangleToolButton',
      tooltip: 'Frakobling',
    },
    RedRectangleAnnotation,
  );

  instance.UI.setHeaderItems((header) => {
    header.getHeader('toolbarGroup-Annotate').get('shapeToolGroupButton').insertBefore({
      type: 'toolButton',
      toolName: dispositionToolName,
    });
  });
};

export const addGreenRectangleTool = (instance) => {
  const {Annotations, annotationManager, Tools, documentViewer} = instance.Core;
  class GreenRectangleAnnotation extends Annotations.CustomAnnotation {
    constructor() {
      super('greenrectangle'); // provide the custom XFDF element name
      this.Subject = 'GreenRectangle';
      // console.log('trianlge constructor runs');
      // console.log('this constructor', this);
      // addText(this);
    }

    draw(ctx, pageMatrix) {
      // the setStyles function is a function on markup annotations that sets up
      // certain properties for us on the canvas for the annotation's stroke thickness.
      this.setStyles(ctx, pageMatrix);
      // first we need to translate to the annotation's x/y coordinates so that it's
      // drawn in the correct location
      // console.log('this draw', this);

      ctx.translate(this.X, this.Y);
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(0, this.Height);
      ctx.lineTo(this.Width, this.Height);
      ctx.lineTo(this.Width, 0);
      // ctx.moveTo(0, this.Height / 2);
      // ctx.lineTo(this.Width, this.Height / 2);
      // ctx.lineTo(this.Width, 0);
      // ctx.lineTo(this.Width, this.Height);
      // ctx.moveTo(this.Width, this.Height / 2);
      ctx.closePath();
      ctx.fill();

      // ctx.fillText('amazing', this.Width / 2, this.Height / 2);
      // ctx.strokeText('test');

      ctx.strokeStyle = 'rgb(0, 255, 0)';
      ctx.stroke();
    }
  }

  // this is necessary to set the elementName before instantiation
  GreenRectangleAnnotation.prototype.elementName = 'greenrectangle';
  annotationManager.registerAnnotationType(
    GreenRectangleAnnotation.prototype.elementName,
    GreenRectangleAnnotation,
  );

  class DispositionCreateTool extends Tools.GenericAnnotationCreateTool {
    constructor(documentViewer) {
      // DispositionAnnotation is the class (function) for our annotation we defined previously
      super(documentViewer, GreenRectangleAnnotation);
    }
  }

  const dispositionToolName = 'AnnotationCreateGreenRectangle';

  const dispositionTool = new DispositionCreateTool(documentViewer);
  instance.UI.registerTool(
    {
      toolName: dispositionToolName,
      toolObject: dispositionTool,
      buttonImage: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
            viewBox="0 0 24 24" fill="currentColor">
            <line x1="1" y1="1" x2="20" y2="1" style="stroke:green;stroke-width:2" />
            <line x1="20" y1="1" x2="20" y2="17" style="stroke:green;stroke-width:2" />
            <line x1="20" y1="17" x2="1" y2="17" style="stroke:green;stroke-width:2" />
            <line x1="1" y1="17" x2="1" y2="1" style="stroke:green;stroke-width:2" />
        </svg>`,
      buttonName: 'greenrectangleToolButton',
      tooltip: 'Fjernstyring',
    },
    GreenRectangleAnnotation,
  );

  instance.UI.setHeaderItems((header) => {
    header.getHeader('toolbarGroup-Annotate').get('shapeToolGroupButton').insertBefore({
      type: 'toolButton',
      toolName: dispositionToolName,
    });
  });
};
