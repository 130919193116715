import {useState, createContext} from 'react';

export const LayoutContext = createContext();

export const LayoutContextProvider = (props) => {
  const [layoutState, setLayoutState] = useState({
    backButton: false,
    callback: () => {},
    showSidebarOnSmallScreen: false,
    showMapOnSmallScreen: false,
    locationUrl: null,
  });

  return (
    <LayoutContext.Provider value={[layoutState, setLayoutState]}>
      {props.children}
    </LayoutContext.Provider>
  );
};
