import CemitLogo from 'assets/icons/cemit_logo_responsive.svg';
import {LoadingWrapper, Logo} from './styles';

const Loading = () => (
  <LoadingWrapper>
    <Logo src={CemitLogo} />
    <p>Loading</p>
  </LoadingWrapper>
);

export default Loading;
