import {useState} from 'react';
import {navigate} from '@reach/router';
import {getAuthApiUrl} from 'lib/fetch';
import {
  AuthFormInner,
  StyledInput,
  ButtonWrapper,
  Feedback,
  AuthContent,
  Button,
  Loader,
} from './styles';

export const parseString = (str) => (str ? `/${str}` : '');

const AuthForm = () => {
  async function authenticate() {
    let accessToTrain =
      JSON.parse(localStorage.getItem('group'))?.group?.includes('sysadmin') ||
      JSON.parse(localStorage.getItem('tabAccess'))?.tabAccess?.includes('train');
    let accessToRail =
      JSON.parse(localStorage.getItem('group'))?.group?.includes('sysadmin') ||
      JSON.parse(localStorage.getItem('tabAccess'))?.tabAccess?.includes('rails');
    let accessToDepot =
      JSON.parse(localStorage.getItem('group'))?.group?.includes('sysadmin') ||
      JSON.parse(localStorage.getItem('tabAccess'))?.tabAccess?.includes('depots');
    let accessToRoutePlanner =
      JSON.parse(localStorage.getItem('group'))?.group?.includes('sysadmin') ||
      JSON.parse(localStorage.getItem('tabAccess'))?.tabAccess?.includes('grps');
    if (accessToRail) {
      navigate('/dashboard/index');
    } else if (accessToDepot) {
      navigate('/dashboard/depots');
    } else if (accessToRoutePlanner) {
      navigate('/dashboard/grps');
    } else if (accessToTrain) {
      navigate('/dashboard/train');
    }
    // }
  }

  let token = localStorage.getItem('token');
  if (token) {
    authenticate();
  }

  const [state, setState] = useState({
    email: '',
    password: '',
    feedback: '',
    status: '',
    spinner: false,
  });

  const onChangeListener = (e) =>
    setState({
      ...state,
      [`${e.target.id}`]: e.target.value,
    });

  const submit = async (e) => {
    console.log(process.env.REACTREACT_APP_URL_APP_MAKE);
    e.preventDefault();
    let authApiUrl = getAuthApiUrl();
    let localhost = 'http://localhost:3000/dev' == authApiUrl;
    setState((prevState) => ({...prevState, spinner: true}));
    const response = await fetch(`${authApiUrl}/makejwt`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: state.email,
        url: localhost ? 'http://localhost:1234' : process.env.REACT_APP_URL,
      }),
    });

    const jsonResponse = await response.json();
    setState((prevState) => ({...prevState, spinner: false}));

    setState({
      ...state,
      feedback: jsonResponse.message,
      status: response.status,
    });
  };
  return (
    <AuthFormInner>
      <AuthContent>
        <h1>Login with one click</h1>
        <p>Submit your email, and we will send you a magic link to login</p>
        <StyledInput
          onChange={onChangeListener}
          placeholder="e-mail"
          value={state.email}
          type="text"
          id="email"
        />

        <ButtonWrapper>
          <Button onClick={(e) => submit(e)} disabled={!state.email.length}>
            Login
          </Button>
          <Loader show={state.spinner}></Loader>
        </ButtonWrapper>
        {state.feedback.length ? (
          <Feedback id="login-feedback" status={state.status}>
            {state.feedback}
          </Feedback>
        ) : null}
      </AuthContent>
    </AuthFormInner>
  );
};

export default AuthForm;
