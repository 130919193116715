import styled from '@emotion/styled';
export const Outer = styled.div`
  margin-bottom: 30px;
`;

export const ChartOuter = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Inner = styled.div`
  display: flex;
`;

export const SideBar = styled.div`
  width: auto;
  padding: 50px 50px;
  min-height: 100vh;
  left: 0;
  top: 0;
  z-index: 99;
  background: #3a4750;
  overflow: hidden;
  min-width: ${(p) => (p.small ? '400px' : '700px')};
`;

export const BackBtn = styled.a`
  position: absolute;
  left: 22px;
  border-radius: 50%;
  background: #fff;
  width: 60px;
  cursor: pointer;
  top: 45px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0, 0);
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #efefef;
  }
  img {
    width: 50%;
  }
  transform: ${(props) => props.disabled && `scale(1, 1)`};
`;
export const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  &:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100px;
    left: 0;
    top: 0;
    z-index: 99;
    background: linear-gradient(90deg, rgba(58, 71, 80, 1) 0%, rgba(58, 71, 80, 0) 100%);
  }

  #map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
`;

export const H1 = styled.h1`
  color: #fff;
  font-size: 40px;
  padding-right: 100px;
  padding-bottom: 50px;
`;

export const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
`;

export const TrackNameWrapper = styled.p`
  margin-bottom: 30px;
  cursor: pointer;
  color: ${(props) => (props.isSelected === true ? 'white' : 'grey')};
`;

export const ScreenWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #3a4750;
  color: white;

  button {
    margin: 20px;
    border: #f6c90e 1px solid;
    border-radius: 5px;
    padding: 10px;
    color: #f6c90e;
  }
`;
