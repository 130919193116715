export const colors = {
  darkBlue: '#3A4750',
  orange: '#FFD952',
  lightBlue: '#485863',
  labelText: '#A3ABB1',
  red: '#FA5760',
  reallyRed: '#FA5760',
  reallyRedTranspartent: 'rgba(255,0,0,0.4)',
  green: '#badc58',
  deepGreen: '#27ae60',
  errorTwist: '#D7FCD4',
  errorTwistAlt: '#48E5C2',
  errorDistance: '#9D9DFA',
  errorDistanceAlt: '#7AB5D8',
  warning: '#F19066',
  lightGreen: '#1DE9B6',
  lightPink: '#B174F7',
  /* New Color */
  /* Text Color */
  textPrimary: '#ffffff',
  textSecondary: '#D0D7DC',
  textTertiary: '#A4B0B9',
  textPlaceholder: 'rgba(185, 195, 202, .5) ;',

  /* Text Border Color */
  borderSubtl: 'E7EBED',
  gray: '#B9C3CA',
};
