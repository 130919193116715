import React, {useEffect, useContext, useState, useRef} from 'react';
import {v4 as uuidv4} from 'uuid';
import {formatName} from '../graph/utils';
import {UserContext} from 'contexts/user-context';
import {DepoHeader} from './styles';
import {LayoutContext} from 'contexts/layout-context';
import {doGetGRPS} from '../utils';
// import WebViewer from '@pdftron/webviewer';
import WebViewer from '@pdftron/pdfjs-express';
import {customizeUI} from './styleUI';
import {connectToServer} from './connectToServer';
import {Circle, PopupBox, BackgroundOverlay} from '../styles';

function GraphPdfTron({graph, route_id}) {
  document.title = `${formatName(graph)} | Cemit`;
  const [layoutState, setLayoutState] = useContext(LayoutContext);
  const [userState] = useContext(UserContext);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [usersConnected, setUsersConnected] = useState([]);
  const [sessionError, setSessionError] = useState(false);

  const viewer = useRef(null);

  if (localStorage.getItem('customColors') === null) {
    localStorage.setItem(
      'customColors',
      JSON.stringify(['#0000ff', '#ff0000', '#ffa54f', '#ffff00']),
    );
  }

  useEffect(() => {
    const today =
      window.location.search.split('date=', 2)[1] ||
      new Date().toISOString().slice(0, 10);
    const CLIENT_LIST = 'CLIENT_LIST';
    const ANNOTATION = 'ANNOTATION';

    let annotManager = null;

    const connection = new WebSocket(
      `${process.env.REACT_APP_GRPS_WS}/${uuidv4()}/${route_id}/${
        userState.email
      }/${today}`,
    );

    connection.onmessage = async (message) => {
      let {type, data} = JSON.parse(message.data);

      if (type === CLIENT_LIST) {
        data?.clientEmail?.length
          ? setUsersConnected(data.clientEmail)
          : setSessionError(true);
      } else if (type === ANNOTATION) {
        await annotManager.drawAnnotationsFromList(
          await annotManager.importAnnotCommand(data.xfdfString),
        );
      }
    };

    doGetGRPS(`routes/${route_id}?date=${today}`).then((data) => {
      setPdfUrl(data.urlGet);
      WebViewer(
        {
          licenseKey: 'CuEgY6Jqj299Pxb3vmH3',
          annotationUser: userState.email,
          path: '/public/pdfjsexpress',
          initialDoc: data.urlGet,
          annotationAdmin: true,
        },
        viewer.current,
      ).then((instance) => {
        annotManager = instance.docViewer.getAnnotationManager();
        //annotManager.setIsAdminUser(true)
        /*  annotManager.setPermissionCheckCallback((author, annotation) => {
          const defaultPermission = annotation.Author === annotManager.getCurrentUser();
        });*/
        customizeUI(instance);
        connectToServer(instance, connection, data?.annotations || []);
      })
    });

    return () => {
      connection?.close();
    };
  }, []);

  useEffect(() => {
    if (pdfUrl) {
      setLayoutState({
        ...layoutState,
        backButton: true,
        locationUrl: 'graph',
      });

      return () => {
        document.title = 'Cemit';
        // for (let key in annotations) delete annotations[key];
        console.log('disconnect');
        // socket.close();
        setLayoutState({
          ...layoutState,
          backButton: false,
        });
      };
    }
  }, [pdfUrl]);

  return (
    <div>
      {sessionError && (
        <BackgroundOverlay>
          <PopupBox>PDF session expired</PopupBox>
        </BackgroundOverlay>
      )}
      <DepoHeader>
        <h1>{formatName(graph)}</h1>
        <div className="users">
          {usersConnected.map((email, index) => (
            <div key={index} title={email}>
              <Circle title={email}>{email?.[0]?.toUpperCase()}</Circle>
            </div>
          ))}
        </div>
      </DepoHeader>
      <div
        className="webviewer"
        ref={viewer}
        style={{height: 'calc(100vh - 90px)'}}
      ></div>
    </div>
  );
}

export default GraphPdfTron;
