import {useState, createContext} from 'react';

export const UserContext = createContext();

export const UserContextProvider = (props) => {
  const [userState, setUserState] = useState({
    state: 'unset',
    id: null,
    email: null,
    iat: null,
  });

  return (
    <UserContext.Provider value={[userState, setUserState]}>
      {props.children}
    </UserContext.Provider>
  );
};
