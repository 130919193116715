/**
 * Component that redirects to the new Visualizer app with a given path
 */
function CrossAppLink({productPath}) {
  let path = '';
  if (productPath === 'home') {
    path = '/home';
  } else if (productPath === 'train') {
    path = process.env.REACT_APP_PATH_TRAIN;
    if (!path) {
      throw new Error('REACT_APP_PATH_TRAIN is not configured');
    }
  } else {
    console.log(`Unknown remote product path ${productPath}`);
    return <></>;
  }
  const token = localStorage.getItem('token');
  const url = process.env.REACT_APP_MAIN_URL + path + (token ? '?token=' + token : '');
  window.location.href = url;
  return <></>;
}

export {CrossAppLink};
