export const routeMapping = {
  rails: process.env.REACT_FEATURE_TRACK && '/dashboard/index',
  train: process.env.REACT_FEATURE_TRAIN && '/dashboard/train',
  depots: process.env.REACT_FEATURE_DEPOTS && '/dashboard/depots',
  grps: process.env.REACT_FEATURE_ROUTE_PLANNER && '/dashboard/grps',
  catenary: process.env.REACT_FEATURE_CATENARY && '/dashboard/catenary',
  stations: process.env.REACT_FEATURE_STATIONS && '/dashboard/stations',
  events: process.env.REACT_FEATURE_EVENTS && '/dashboard/events',
  switch: process.env.REACT_FEATURE_SWITCH && '/dashboard/switch',
  budget: process.env.REACT_FEATURE_BUDGET && '/dashboard/budget',
};

export const loadLocalAuthData = () => {
  if (process.env.REACT_DISABLE_AUTH_CHECK === 'true') {
    let json;
    if (
      process.env.REACT_APP_FORCED_JWT !== undefined &&
      process.env.REACT_APP_FORCED_JWT != ''
    ) {
      try {
        json = JSON.parse(process.env.REACT_APP_FORCED_JWT);
        console.log('Authenticated from env variable', json);
      } catch (error) {
        console.log('Failed to authenticate from env variable', error);
        json = {};
      }
    } else {
      console.log('Authenticated with hardcoded values (sysadmin)');
      json = {
        message: {
          id: 'admin',
          email: 'admin@example.com',
          group: 'sysadmin',
        },
      };
    }
    return json;
  }
  return null;
};
