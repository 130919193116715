import styled from '@emotion/styled';
import {colors} from 'ui';

export const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${colors.darkBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 50px;
    color: ${colors.orange};
    font-size: 30px;
    text-align: center;
  }
`;

export const Logo = styled.img`
  width: 200px;
`;
