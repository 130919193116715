const hostName = `${process.env.REACT_APP_GRPS_API}`;
// const hostName = `${process.env.REACT_APP_LOCAL_GRPS}`;

export const doGetGRPS = async (url, query, queryValue) => {
  // eslint-disable-next-line no-restricted-globals
  try {
    const response = await fetch(
      `${hostName}/${url}${query ? `?${query}=${queryValue}` : ''}`,
      {
        headers: {
          Accept: 'application/json',
          JWT_TOKEN: localStorage.getItem('token'),
        },
      },
    );
    return response.json();
  } catch (error) {
    return {
      error,
    };
  }
};

export const doDeleteGRPS = async (url, query, queryValue) => {
  // eslint-disable-next-line no-restricted-globals
  try {
    const response = await fetch(
      `${hostName}/${url}${query ? `?${query}=${queryValue}` : ''}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          JWT_TOKEN: localStorage.getItem('token'),
        },
      },
    );
    return response.json();
  } catch (error) {
    return {
      error,
    };
  }
};

export const changeHandler = (e, multiple, selectedFiles, setSelectedFiles, index) => {
  if (multiple) {
    let temp = {};
    Array.from(e.target.files).forEach((file, index) => {
      temp['multiple' + index] = file;
    });
    setSelectedFiles({...selectedFiles, ...temp});
  } else {
    setSelectedFiles({...selectedFiles, [index]: e.target.files[0]});
  }
};

export const submitHandler = (
  e,
  mulitple,
  depots,
  selectedFiles,
  setDepots,
  setSelectedFiles,
  dateUrl,
  index,
  setPostError,
  selectedDate,
) => {
  let res;
  let depotsCopy = depots.slice();
  let depoIdToIndex = {};
  for (let [index, depo] of depots.entries()) {
    depoIdToIndex[depo.routeId] = index;
  }

  // "BLAD NR. 2T, KONGSVINGER - ELVERUM_20210428 - Utskrift 20210427.pdf".split(',').pop().split('_')[0].toLowerCase().replaceAll(" ", "")
  // .split(',').pop().split('_')[0].toLowerCase().replaceAll(" ", "")

  if (mulitple) {
    try {
      res = selectedFiles;

      let singleFiles = document.querySelectorAll('.choose');
      for (let singleFile of singleFiles) {
        if (singleFile.dataset.id) {
          if (singleFile.value) {
            depotsCopy[depoIdToIndex[singleFile.dataset.id]].urlGet = 'uploaded';
          }
        }
        singleFile.value = null;
      }

      let routeNameToRouteId = {};
      for (let depo of depotsCopy) {
        routeNameToRouteId[depo.name.toUpperCase()] = depo.routeId;
      }

      for (let uploadedFile in selectedFiles) {
        if (uploadedFile.startsWith('multiple')) {
          let id =
            routeNameToRouteId[
              selectedFiles[uploadedFile].name.split(',').pop().split('_')[0].trim()
            ];
          if (!depotsCopy[depoIdToIndex[id]]) {
            delete res[uploadedFile];
            setPostError(true);
            continue;
          }
          depotsCopy[depoIdToIndex[id]].urlGet = 'uploaded';
          Object.defineProperty(
            res,
            id,
            Object.getOwnPropertyDescriptor(res, uploadedFile),
          );
          delete res[uploadedFile];
          // console.log(res);
        }
      }

      doPostPDF(res, 'routes', 'date', dateUrl).then(() => {
        setDepots((preData) => ({
          ...preData,
          [selectedDate]: depotsCopy,
        }));
        setSelectedFiles({});
      });
    } catch (e) {
      setPostError(true);
      console.log('error', e);
      setSelectedFiles({});
      // setTimeout(() => {
      //   setPostError(false);
      // }, 15000);
    }
  } else {
    let obj = {};
    obj[index] = selectedFiles[index];
    delete selectedFiles[index];
    res = obj;
    e.target.previousSibling.value = null;
    let {[index]: value, ...rest} = selectedFiles;
    depotsCopy[depoIdToIndex[e.target.previousSibling.dataset.id]].urlGet = 'uploaded';
    doPostPDF(res, 'routes', 'date', dateUrl).then(() => {
      setDepots((preData) => ({
        ...preData,
        [selectedDate]: depotsCopy,
      }));
      setSelectedFiles(rest);
    });
  }
};

const doPostPDF = async (filesObject, url, query, queryValue) => {
  let formData = new FormData();

  // let files = [];
  let routeIds = [];
  for (let file in filesObject) {
    let binary = filesObject[file];
    formData.append('files', binary);
    routeIds.push(file);
  }

  routeIds = routeIds.join(', ');
  formData.append('route_ids', routeIds);

  try {
    await fetch(
      `${hostName}/${url}${query ? `?${query}=${queryValue}` : ''}`,
      // 'http://localhost:8080/',
      {
        method: 'POST',
        body: formData,
        headers: {
          JWT_TOKEN: localStorage.getItem('token'),
        },
      },
    );
  } catch (error) {
    return {
      error,
    };
  }
};

// "BLAD NR. 2T, KONGSVINGER - ELVERUM_20210428 - Utskrift 20210427.pdf"
// export const routeNameToRouteId = {
//   'lillestrøm-charlottenberg': '60d5c6e7b3d5018b29fc17f7',
//   'kongsvinger-elverum': '60d5c6e8b3d5018b29fc17f9',
//   'skøyen-oslos-kornsjø(v.l)': '60d5c6e8b3d5018b29fc17fb',
//   'skøyen-sarpsborg': '60d5c6e8b3d5018b29fc17fd',
//   'skøyen-oslos-gjøvik': '60d5c6e8b3d5018b29fc17ff',
//   'myrdal-flåm': '60d5c6e8b3d5018b29fc1801',
//   'bergen-hønefoss': '60d5c6e8b3d5018b29fc1803',
//   'oslos-drammen': '60d5c6e8b3d5018b29fc1805',
//   'eidsvoll-dombås': '60d5c6e8b3d5018b29fc1807',
//   'trondheim-dombås': '60d5c6e8b3d5018b29fc1809',
//   'dombås-åndalsnes': '60d5c6e8b3d5018b29fc180b',
//   'støren-tynset-hamar': '60d5c6e8b3d5018b29fc180d',
//   'grong-trondheims': '60d5c6e8b3d5018b29fc180f',
//   'bodø-grong': '60d5c6e8b3d5018b29fc1811',
//   ofotbanen: '60d5c6e8b3d5018b29fc1813',
//   'drammen-neslandsvatn': '60d5c6e8b3d5018b29fc1815',
//   'nordagutu-kristiansand': '60d5c6e8b3d5018b29fc1817',
//   'kristiansand-stavanger': '60d5c6e8b3d5018b29fc1819',
//   'hokksund-hønefoss': '60d5c6e8b3d5018b29fc181b',
//   'kongsberg-hønefoss': '60d5c6e8b3d5018b29fc181f',
//   'drammen-larvik-nordagutu': '60d5c6e8b3d5018b29fc1821',
//   'arendal-nelaug': '60d5c6e8b3d5018b29fc1823',
//   gardemobanen: '60d5c6e8b3d5018b29fc1825',
// };
