const serializer = new XMLSerializer();

const ANNOTATION = 'ANNOTATION';

export const connectToServer = (instance, connection, initialAnnotation) => {
  const {documentViewer, annotationManager} = instance.Core;
  const annotManager = instance.docViewer.getAnnotationManager();

  // import all initial annotation, when document load finish
  documentViewer.addEventListener('documentLoaded', () => {
    initialAnnotation.forEach(async (item) => {
      const annotations = await annotationManager.importAnnotCommand(item.xfdfString);
      annotations.forEach((annotation) => {
        annotationManager.redrawAnnotation(annotation);
      });
    });
  });

  annotManager.addEventListener('annotationChanged', async (e) => {
    /*
     * if annotation is changed by importing any object,
     * it will return from this function
     */
    if (e.imported) {
      return;
    }

    const parser = new DOMParser();
    const commandData = parser.parseFromString(
      await annotManager.exportAnnotCommand(),
      'text/xml',
    );

    commandData.getElementsByTagName('add')[0].childNodes.forEach((child) => {
      sendAnnotationToServer(child, 'add', connection);
    });

    commandData.getElementsByTagName('modify')[0].childNodes.forEach((child) => {
      sendAnnotationToServer(child, 'modify', connection);
    });

    commandData.getElementsByTagName('delete')[0].childNodes.forEach((child) => {
      sendAnnotationToServer(child, 'delete', connection);
    });
  });
};

const convertToXfdf = (changedAnnotation, action) => {
  let xfdfString = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields />`;

  switch (action) {
    case 'add':
      xfdfString += `<add>${changedAnnotation}</add><modify /><delete />`;
      break;
    case 'modify':
      xfdfString += `<add /><modify>${changedAnnotation}</modify><delete />`;
      break;
    case 'delete':
      xfdfString += `<add /><modify /><delete>${changedAnnotation}</delete>`;
      break;
  }

  xfdfString += `</xfdf>`;
  return xfdfString;
};

const sendAnnotationToServer = (annotation, action, connection) => {
  if (annotation.nodeType !== annotation.TEXT_NODE) {
    const annotationString = serializer.serializeToString(annotation);
    connection.send(
      JSON.stringify({
        type: ANNOTATION,
        annotationAction: action,
        data: {
          annotationId:
            action === 'delete' ? annotation.innerHTML : annotation.getAttribute('name'),
          xfdfString: convertToXfdf(annotationString, action),
        },
      }),
    );
  }
};
