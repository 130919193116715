import circleIcon from './icons/toggraf_f2_trekant_liten.png';
import triangleIcon from './icons/toggraf_g3_sirkel_liten.png';
import squareRedIcon from './icons/square-red-liten.png';
import squareBlueIcon from './icons/square-blue-liten.png';
//import delayIcon from './icons/delay-red-liten.png';
//import frakoblingIcon from './icons/frakobling-red-liten.png';
//import fjernstyringIcon from './icons/fjernstyring-green-liten.png';

// import triangleBlackIcon from './icons/triangle-black.png';
// import triangleBlackIcon2 from './icons/triangle-black2.png';
import triangleBlackIcon3 from './icons/triangle-black-rounded-png.png';
import triangleBlue from './icons/triangle-blue-liten.jpg';

import {
  updateTools,
  disableTools,
  setStyles,
  setStylesLines,
  colors,
  thickLines,
  // addText,
  addDispositionTool,
  addRedRectangleTool,
  addGreenRectangleTool,
} from './utils';

export const customizeUI = (instance) => {
  const FitMode = instance.UI.FitMode;
  const {
    Annotations,
    // eslint-disable-next-line no-unused-vars
    annotationManager,
    // documentViewer,
  } = instance.Core;

  addDispositionTool(instance);
  addRedRectangleTool(instance);
  addGreenRectangleTool(instance);

  // documentViewer.addEventListener('documentLoaded', () => {
  //   annotationManager.addEventListener(
  //     'annotationChanged',
  //     (annotations, action) => {
  //       if (
  //         action === 'add' &&
  //         annotations[0].constructor.name === 'DispositionAnnotation'
  //       ) {
  // let ee0 = annotations[0].getGroupedChildren();
  // console.log(
  //   'before annotations[0].getGroupedChildren()----------',
  //   ee0
  // );
  // setTimeout(() => {
  //   let ee = annotations[0].getGroupedChildren();
  //   console.log('annotations[0].getGroupedChildren() timeout', ee);
  //   if (annotations[0].getGroupedChildren().length === 0) {
  //     addText(annotationManager, Annotations, annotations[0]);
  //   }
  // }, 500);
  //       }
  //     }
  //   );
  // });

  // documentViewer.addEventListener('documentLoaded', () => {
  //   // set the tool mode to our tool so that we can start using it right away
  //   // instance.UI.setToolMode(dispositionToolName);
  // });

  instance.UI.setFitMode(FitMode.FitWidth);
  instance.UI.disableElements(['toolbarGroup-Edit']);
  instance.UI.disableElements(['toolbarGroup-Insert']);
  instance.UI.disableElements(['toolbarGroup-Forms']);
  instance.UI.disableElements(['toolbarGroup-Shapes']);
  instance.UI.disableElements(['toolbarGroup-FillAndSign']);
  instance.UI.enableElements(['menuOverlay', 'downloadButton']);

  instance.Core.documentViewer.getTool('AnnotationCreateRubberStamp').setStandardStamps([
    circleIcon,
    triangleIcon,
    // triangleBlackIcon,
    // triangleBlackIcon2,
    triangleBlackIcon3,
    triangleBlue,
    squareRedIcon,
    squareBlueIcon,
    //delayIcon,
    //frakoblingIcon,
    //fjernstyringIcon
  ]);

  setStyles.map((setStyle, index) => {
    instance.Core.documentViewer.getTool(setStyle).setStyles({
      TextColor: new Annotations.Color(...colors[index % 4]),
      FontSize: '8pt',
      Font: 'Lato, sans-serif',
      TextAlign: 'center',
    });
  });

  setStylesLines.map((setStyle, index) => {
    instance.Core.documentViewer.getTool(setStyle).setStyles({
      StrokeColor: new Annotations.Color(...colors[index % 4]),
      StrokeThickness: thickLines.includes(index) ? 0.9 : 7,
      Opacity: thickLines.includes(index) ? 1 : 0.6,
    });
  });

  instance.UI.setHeaderItems(function (header) {
    header.getHeader('toolbarGroup-Annotate').push({
      type: 'toolGroupButton',
      toolGroup: 'lineTools',
      dataElement: 'lineToolGroupButton',
      title: 'annotation.line',
    });
    header.getHeader('toolbarGroup-Annotate').push({
      type: 'toolGroupButton',
      toolGroup: 'polyLineTools',
      dataElement: 'polyLineToolGroupButton',
      title: 'annotation.polyline',
    });
    header.getHeader('toolbarGroup-Annotate').push({
      type: 'toolGroupButton',
      toolGroup: 'rubberStampTools',
      dataElement: 'rubberStampToolGroupButton',
      // title: 'annotation.rubberstamp',
      // title: 'Rubber Stamp',
      title: 'Symbol',
    });
  });

  updateTools.map((tool) => {
    instance.UI.updateTool(tool, {
      buttonGroup: '',
    });
  });
  //debugger
  //annotationManager.promoteUserToAdmin()
  instance.UI.disableTools(disableTools);
};
