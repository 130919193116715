import AuthFormComponent from './auth-form';
import React, {useContext, useEffect} from 'react';
import {Outer, AuthForm, ImageWrapper, Logo} from './styles';
import {UserContext} from 'contexts/user-context';
import AuthPageImage from 'assets/images/auth-page.png';
import LogoSvg from 'assets/images/cemit-visualizer-logo.svg';
import {Redirect} from '@reach/router';
import Loading from 'components/loading';
import {loadLocalAuthData, routeMapping} from './utils';
import {getAuthApiUrl} from 'lib/fetch/index';
import * as Sentry from '@sentry/browser';

/**
 * Component which authenticates the token and stores the user information
 * in the user context.
 *
 * It does not authorize access to routes, that is handled by @see ProtectedRoute
 */
const AuthenticateUser = ({children}) => {
  const [userState, setUserState] = useContext(UserContext);
  if (userState.state === 'verified') {
    return <>{children}</>;
  }
  const location = window.location;
  let token = '';
  let hasQueryToken = false;
  const queryParams = new URLSearchParams(location.search);
  if (queryParams.has('token')) {
    token = queryParams.get('token');
    hasQueryToken = true;
  } else {
    token = localStorage.getItem('token');
  }

  // If the query contains a token parameter store it in local storage and then redirect to a url without it
  useEffect(() => {
    if (hasQueryToken) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('token');
      localStorage.setItem('token', token);
      window.location.search = queryParams.toString();
    }
  }, [hasQueryToken]);

  useEffect(() => {
    async function authenticate() {
      let json;
      let response;
      if (process.env.REACT_DISABLE_AUTH_CHECK !== 'true') {
        // This is the default code path and must be used for deployed production/development
        const url = getAuthApiUrl()
        response = await fetch(`${url}/checkjwt`, {
          method: 'POST',
          body: JSON.stringify({token}),
        });
        json = await response.json();
      } else {
        // This is the code path that can be used for local development
        response = {
          status: 200,
        };
        json = loadLocalAuthData();
        if (process.env.REACT_APP_FORCED_TOKEN !== undefined) {
          // Store forced token, needed for APIs
          localStorage.setItem('token', process.env.REACT_APP_FORCED_TOKEN);
        }
      }

      if (
        response?.status !== 200 ||
        json?.message === undefined ||
        json?.message?.email === undefined
      ) {
        if (process.env.REACT_DISABLE_AUTH_CHECK !== 'true') {
          localStorage.removeItem('token');
        }
        await setUserState({
          state: 'unverified',
          id: null,
          email: null,
          name: null,
          trackAccess: null,
          group: null,
          tabAccess: null,
          iat: null,
          admin: null,
        });
        return;
      }
      localStorage.setItem(
        'trackAccess',
        JSON.stringify({
          trackAccess: json?.message?.trackAccess,
        }),
      );
      localStorage.setItem(
        'tabAccess',
        JSON.stringify({
          tabAccess: json?.message?.tabAccess,
        }),
      );
      localStorage.setItem(
        'group',
        JSON.stringify({
          group: json?.message?.group,
        }),
      );

      const tabAccess = json?.message?.tabAccess;
      const groupName = json?.message?.group;

      Sentry.setUser({email: json?.message?.email});
      await setUserState({
        state: 'verified',
        id: json?.message?.id,
        email: json?.message?.email,
        name: json?.message?.name,
        trackAccess: json?.message?.trackAccess,
        group: groupName,
        tabAccess: tabAccess,
        iat: json?.message?.iat,
        admin: json?.message?.admin,
      });
    }
    if (process.env.REACT_DISABLE_AUTH_CHECK === 'true' || token) {
      authenticate();
    } else {
      // No token in local storage or query parameter, set as unverified
      setUserState({
        state: 'unverified',
        id: null,
        email: null,
        name: null,
        trackAccess: null,
        group: null,
        tabAccess: null,
        iat: null,
        admin: null,
      });
    }
  }, [token]);

  return <>{children}</>;
};

const IndexPage = () => {
  const [userState] = useContext(UserContext);
  if (userState.state !== 'verified') {
    throw new Error('User is not authenticated');
  }

  let to = '/dasboard/profile';
  const group = userState.group;
  const tabAccess = userState.tabAccess;

  // User is authenticated, decide which route should be the default
  if (group?.includes('sysadmin')) {
    for (let tab in routeMapping) {
      if (routeMapping[tab]) {
        to = routeMapping[tab];
        break;
      }
    }
  } else if (Array.isArray(tabAccess)) {
    for (let tab in routeMapping) {
      if (tabAccess.includes(tab)) {
        to = routeMapping[tab];
        break;
      }
    }
  }
  return <Redirect noThrow from="/" to={to} />;
};

const VisualizerAccess = ({children}) => {
  const [userState] = useContext(UserContext);
  if (userState.state === 'unset') {
    return <Loading />;
  }
  if (userState.state === 'verified') {
    return children;
  }

  // User is not authenticated, show login form
  return (
    <Outer>
      <AuthForm>
        <AuthFormComponent />
      </AuthForm>
      <ImageWrapper>
        <Logo>
          <img src={LogoSvg} alt="Cemit Logo" width="248" height="51" />
        </Logo>
        <img src={AuthPageImage} alt="Artifact" />
      </ImageWrapper>
    </Outer>
  );
};

export default IndexPage;
export {AuthenticateUser, VisualizerAccess};
